import React, { useState, useEffect, useRef, Suspense } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { PerspectiveCamera, useHelper } from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import Logo from '../ThreeLogo/';
import { useWindowDimensions } from '../../utils/utils';
extend({ OrbitControls });

const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls component.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls
    const {
        camera,
        gl: { domElement },
    } = useThree();
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame((state) => controls.current.update());
    return <orbitControls ref={controls} args={[camera, domElement]} />;
};

// TODO: Implement a camera animation on mouse move.

function CameraController() {
    const { size } = useThree();
    const [scene] = useState();
    const composer = useRef();
    useEffect(
        () => scene && composer.current.setSize(size.width, size.height),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [size]
    );

    useFrame((state) => {
        state.camera.lookAt(0, 2.25, 0);
    });
    return null;
}

const EmidasLogoScene = (props) => {
    const { width } = useWindowDimensions();
    const pointLight = useRef();
    const pointLight2 = useRef();
    const rectAreaLight = useRef();
    const [cameraDistance, setCameraDistance] = useState(5);

    useEffect(() => {
        if (width > 1280) {
            if (cameraDistance !== 5) setCameraDistance(5);
        } else if (width > 360) {
            let offset = 1280 - 360;

            let newDistance = 5 + 8 * (1 - (width - 360) / offset);
            if (cameraDistance !== newDistance) setCameraDistance(newDistance);
        }
    }, [cameraDistance, width]);

    RectAreaLightUniformsLib.init();
    useHelper(rectAreaLight, RectAreaLightHelper, 0.5, 'blue');

    useFrame((state) => {
        const t = state.clock.getElapsedTime();
        pointLight.current.position.set(
            0 + 20 * Math.cos(t / 2),
            10 + 12 * Math.sin(t / 2),
            10
        );
        pointLight2.current.position.set(
            -10 + 14 * -Math.cos(t / 2),
            -10 + 8 * Math.sin(t / 2),
            10
        );
    });

    return (
        <>
            <pointLight ref={pointLight} position={[0, 10, 10]} intensity={1} />
            <pointLight
                ref={pointLight2}
                position={[-10, -10, 10]}
                intensity={1}
            />
            <PerspectiveCamera
                makeDefault
                far={2000}
                near={0.1}
                fov={70}
                position={[0, 0.25, cameraDistance]}
            />
            <CameraController />
            {props.orbitControl ? <CameraControls /> : null}
            <Suspense>
                <Logo position={[0, 0, 0]} scale={[0.02, 0.02, 0.02]} />
            </Suspense>
            <EffectComposer>
                <Bloom
                    luminanceThreshold={0}
                    luminanceSmoothing={0.9}
                    height={300}
                />
            </EffectComposer>
        </>
    );
};

export default EmidasLogoScene;
