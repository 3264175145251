import React, { useState, useRef } from 'react';
import './styles.scss';
import SelectableTitle from '../../components/SelectableTitle';
//import Localization from '../../utils/localization';

// This is an example of a page with table of content

const aboutSections = [
    {
        title: 'Heading ONE',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.`,
    },
    {
        title: 'Heading TWO',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Three',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Four',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Five',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Six',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Seven',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
    {
        title: 'Heading Height',
        text: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, 
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt 
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
            magni dolores eos qui ratione voluptatem sequi nesciunt.`,
    },
];

const About = () => {
    const [hoveredTitle, setHoveredTitle] = useState(null);
    const sectionsRefs = useRef([]);

    //https://www.pluralsight.com/tech-blog/getting-size-and-position-of-an-element-in-react/

    const renderAboutSection = (aboutSection, index) => {
        return (
            <div
                className="section"
                key={index}
                ref={(el) => (sectionsRefs.current[index] = el)}>
                <SelectableTitle className="text" text={aboutSection.title} />
                <p className="text">{aboutSection.text}</p>
            </div>
        );
    };

    const renderTableOfContent = (aboutSection, index) => {
        return (
            <div
                key={index}
                className="section"
                onMouseEnter={() => {
                    setHoveredTitle(aboutSection);
                }}
                onMouseLeave={() => {
                    setHoveredTitle(null);
                }}
                onClick={() => {
                    window.scrollTo(
                        0,
                        sectionsRefs.current[index].getBoundingClientRect().y -
                            16
                    );
                }}>
                <SelectableTitle
                    className="text"
                    selected={hoveredTitle === aboutSection}
                    text={aboutSection.title}
                />
            </div>
        );
    };

    return (
        <div className="about mt-navbarheight">
            <div className="table-content">
                {aboutSections.map((aboutSection, index) => {
                    return renderTableOfContent(aboutSection, index);
                })}
            </div>
            {aboutSections.map((aboutSection, index) => {
                return renderAboutSection(aboutSection, index);
            })}
        </div>
    );
};

export default About;
