import React, { useState, useEffect } from 'react';

const beforeClassName =
    "before:ml-[2px] before:content-[' '] before:absolute before:top-0 before:left-0 before:h-full before:w-6/12 before:skew-x-[-15deg] before:animated-all";

export default function Slash(props) {
    const [style, setStyle] = useState(null);

    useEffect(() => {
        // Initialize slash values
        if (props.hidden) {
            setStyle({ width: 0, height: 0 });
        } else {
            let width = props.width ? props.width : 0.75;
            let height = props.height ? props.height : 2;
            setStyle({
                width: width + 'rem',
                height: height + 'rem',
                borderRadius: width / 8 + 'rem',
            });
        }
    }, [props.width, props.height, props.hidden]);
    return (
        <div
            className={`flex justify-center items-center flex-wrap ${props.className}`}>
            <div
                className={`relative flex w-0 h-0 justify-center items-center animated-all ${
                    props.className
                } ${beforeClassName} ${
                    props.color === 'gnogen-black'
                        ? 'before:bg-gnogen-black'
                        : 'before:bg-gnogen-gold'
                }`}
                style={style}
            />
        </div>
    );
}
