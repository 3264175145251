import React, { useEffect } from 'react';
import Localization from '../../utils/localization';
import elrondTitle from '../../assets/images/elrond-title.png';
import elrondLogo from '../../assets/images/elrond-icon-shadowed.png';
import Divider from '../../components/Divider';

let urls = [
    {
        title: Localization.elrond.twitter,
        url: 'https://twitter.com/ElrondNetwork/',
    },
    { title: Localization.elrond.elrondHome, url: 'https://elrond.com/' },
    {
        title: Localization.elrond.whitepaper,
        url: 'https://elrond.com/assets/files/elrond-whitepaper.pdf',
    },
    {
        title: Localization.elrond.maiarExchange,
        url: 'https://www.maiar.exchange/',
    },
    {
        title: Localization.elrond.maiarWallet,
        url: 'https://wallet.elrond.com',
    },
];

export default function Elrond(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="elrond pt-navbarheight w-full h-full mb-8 max-w-8xl flex flex-col items-center ml-auto mr-auto px-2 mt-4">
            {/* Top section */}
            <div className="w-full flex flex-col md:flex-row items-center">
                <div className="flex flex-col items-start py-12 text-white font-gnogen text-4xl lg:text-7xl">
                    {Localization.elrond.elrondTextsv2.map((text, index) => {
                        if (
                            index ===
                            Localization.elrond.elrondTextsv2.length - 1
                        ) {
                            return (
                                <div className="flex py-1">
                                    <span className="text-shadow">{text}</span>
                                    &nbsp;
                                    <img
                                        className="w-28 lg:w-56 object-contain"
                                        src={elrondTitle}
                                        alt={index}
                                    />
                                </div>
                            );
                        } else {
                            return (
                                <div className="flex py-1">
                                    <span className="text-shadow">{text}</span>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className="m-4 max-w-xs mx-auto">
                    <img src={elrondLogo} alt={'ELROND'} />
                </div>
            </div>
            <Divider
                className="max-w-8xl"
                dividerClassName="border-gnogen-black"
            />
            {/* Bottom section */}
            <div className="flex flex-col w-full md:flex-row justify-between py-12">
                <div className="flex flex-col items-start py-28 text-white font-gnogen">
                    {Localization.elrond.elrondBottomElements.map((text) => {
                        return (
                            <div className="flex flex-col py-8">
                                <span className="text-shadow font-roboto-black text-2xl lg:text-4xl">
                                    {text[0]}
                                </span>
                                <span className="text-black font-roboto-black text-sm lg:text-base">
                                    {text[1]}
                                </span>
                            </div>
                        );
                    })}
                </div>
                <div className="flex items-end justify-center w-full md:w-auto">
                    {urls.map((element) => {
                        return (
                            <div
                                className="px-2 h-fit transition ease-in-out hover:-translate-y-8 duration-300 cursor-pointer"
                                onClick={() => {
                                    window.open(element.url, '_blank');
                                }}>
                                <span className="h-max w-12 sm:w-auto text-border text-vertical text-transparent font-lato font-bold text-5xl lg:text-8xl ml-auto hover:text-black transition ease-in-out">
                                    {element.title}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Divider
                className="max-w-8xl"
                dividerClassName="border-gnogen-black"
            />
        </div>
    );
}
