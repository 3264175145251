import React, { useEffect, useRef, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import EmidasLogoScene from '../../components/EmidasLogoScene';
import gnogenLogo from '../../assets/images/logo-transparent-white-1.png';
import comicsLogo from '../../assets/images/comics-logo.png';
import { collections } from '../../utils/config';
import Localization from '../../utils/localization';
import GnogenLoader from '../../components/GnogenLoader/';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { ArrowRightIcon } from '@heroicons/react/outline';

const ComicRead = (props) => {
    let { id, act } = useParams(); // Unpacking and retrieve id
    const [collection, setCollection] = React.useState(null);
    const [previousAct, setPreviousAct] = React.useState(null);
    const [currentAct, setCurrentAct] = React.useState(null);
    const [nextAct, setNextAct] = React.useState(null);
    const [hoveredItem, setHoveredItem] = React.useState({});
    const containerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Parse through the collection items and retrieve all unique selectors
        collections.forEach((col, index) => {
            if (col.id === id) {
                if (act !== null && act !== undefined) {
                    try {
                        let actIndex = parseInt(act) - 1;
                        if (col.comics.length > actIndex) {
                            setCurrentAct(col.comics[actIndex]);
                            if (actIndex > 0) {
                                setPreviousAct(col.comics[actIndex - 1]);
                            }
                            if (actIndex < col.comics.length - 1) {
                                setNextAct(col.comics[actIndex + 1]);
                            }
                        } else {
                            navigate('/collections/' + id);
                            return;
                        }
                    } catch (e) {
                        navigate('/collections/' + id);
                        return;
                    }
                } else {
                    navigate('/collections/' + id);
                    return;
                }
                setCollection(col);
                return;
            }
        });
    }, [setCollection, setCurrentAct, id, act, navigate]);

    if (currentAct)
        return (
            <div className="w-full flex flex-col items-center overflow-x-hidden pt-navbarheight max-w-7xl mx-auto">
                <div
                    className="w-full flex flex-col items-center justify-center bg-black pb-16 relative"
                    ref={containerRef}>
                    <div
                        className="absolute top-4 left-4 cursor-pointer"
                        onClick={() => {
                            navigate(-1);
                        }}>
                        <ArrowLeftIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                        />
                    </div>
                    <video
                        className={`h-[28rem] sm:h-[48rem] object-contain h-fit my-12`}
                        autobuffer="autobuffer"
                        preload="preload"
                        autoPlay
                        loop
                        muted
                        playsInline
                        onClick={() => {
                        }}>
                        <source type="video/mp4" src={currentAct?.video} />
                    </video>
                    <span
                        className={`text-white text-2xl sm:text-4xl font-gnogen py-8`}>
                        {currentAct?.title}
                        <span className="hidden sm:inline">
                            {': ' + currentAct?.subtitle}
                        </span>
                    </span>
                    <div className="flex flex-col py-8">
                        {currentAct &&
                            currentAct.texts &&
                            currentAct.texts.map((actText, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="text-xl font-bold"
                                        dangerouslySetInnerHTML={{
                                            __html: actText,
                                        }}></p>
                                );
                            })}
                    </div>
                    <div className="w-full flex">
                        {previousAct && (
                            <div
                                className={`flex items-center mr-auto text-white border border-gnogen-gray rounded hover:border-gnogen-gold cursor-pointer mx-2 sm:mx-0 w-48 sm:72 md:w-96 h-28 sm:h-20 px-2 group`}
                                onClick={() => {
                                    navigate(
                                        '/collections/' +
                                            collection.id +
                                            '/' +
                                            (parseInt(act) - 1)
                                    );
                                }}>
                                <ArrowLeftIcon
                                    className="h-4 min-w-[1rem] w-4 text-gnogen-gray mr-auto ml-2 group-hover:text-gnogen-gold"
                                    aria-hidden="true"
                                />
                                <span className="">
                                    {previousAct.title +
                                        ': ' +
                                        previousAct.subtitle}
                                </span>
                            </div>
                        )}
                        <div className="w-2 mx-auto" />

                        {nextAct && (
                            <div
                                className={`flex items-center ml-auto text-white border border-gnogen-gray rounded hover:border-gnogen-gold cursor-pointer mx-2 sm:mx-0 w-48 sm:72 md:w-96 h-28 sm:h-20 px-2 group`}
                                onClick={() => {
                                    navigate(
                                        '/collections/' +
                                            collection.id +
                                            '/' +
                                            (parseInt(act) + 1)
                                    );
                                }}>
                                <span className="">
                                    {nextAct.title + ': ' + nextAct.subtitle}
                                </span>

                                <ArrowRightIcon
                                    className="h-4 min-w-[1rem] w-4 text-gnogen-gray ml-auto mr-2 group-hover:text-gnogen-gold"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
};

export default ComicRead;
