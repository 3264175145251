import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

class LightLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate() {}

    render() {
        return (
            <div className="h-full bg-gnogen-gold text-black relative flex flex-col items-center min-h-screen">
                <Header dark dashColor="gnogen-black" />
                <div className="z-20 w-full h-full">
                    <Outlet />
                </div>
                <Footer dark />
            </div>
        );
    }
}
export default LightLayout;
