import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

function PrivacyModal(props) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={props.handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full z-50 max-h-[80vh]">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-roboto">
                                            <Dialog.Title
                                                as="span"
                                                className="text-6xl font-gnogen text-shadow font-medium text-gray-900 flex items-center justify-between mb-4">
                                                Privacy Policy
                                                <div
                                                    className="flex items-center justify-center h-12 w-12 rounded-full"
                                                    onClick={props.handleClose}>
                                                    <XIcon
                                                        className="h-6 w-6 text-black"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                {/* THE FOLLOWING DIV WAS GENERATED FROM A WORD FILE WITH THOSE 3 STEPS:
                                                 *** 1- COPY THE WORD DOCUMENT CONTENT INTO THIS WEB TOOL: https://wordtohtml.net/
                                                 *** 2- COPY THE RESULT INOT THIS MINIFIER TOOL: https://beautifytools.com/html-minifier.php#:~:text=Online%20HTML%20Minifier%20removes%20all,absolute%20URLs%20to%20relative%20URLs.
                                                 *** 3- COPY THE RESULT INTO THIS HTML -> JSX CONVERTER: https://magic.reactjs.net/htmltojsx.htm
                                                 *** THE RESULT IS LARGER THAN SHOULD PROBABLY BE BUT TAKES WAY LESS TIME TO GENERATE THAN MANUALLY RE-TYPING THE DOC
                                                 */}
                                                <div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Gnogen Studios
                                                                Privacy Policy
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Data protection
                                                            practices of Gnogen
                                                            Studios are
                                                            described in this
                                                            Privacy Policy and
                                                            applies to
                                                            information we may
                                                            collect and use when
                                                            you access or use
                                                            the site. Please
                                                            read the Privacy
                                                            Policy carefully to
                                                            understand how your
                                                            information is
                                                            handled. The use of
                                                            our site and other
                                                            ways we may interact
                                                            with you as
                                                            described in this
                                                            policy will be
                                                            referred to as
                                                            “Services”.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                COLLECTED
                                                                INFORMATION
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            As outlined below,
                                                            when we provide
                                                            Services to you, we
                                                            may obtain and
                                                            collect information.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            You are providing
                                                            information directly
                                                            to us, such as
                                                            contact information,
                                                            email address and
                                                            any other additional
                                                            information you
                                                            choose to provide
                                                            when using our
                                                            Services.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            In addition to the
                                                            information you
                                                            provide, there is
                                                            also automated
                                                            information being
                                                            collected when using
                                                            our Services. Your
                                                            browser
                                                            type/language,
                                                            operating system, IP
                                                            address, general
                                                            location based on
                                                            your IP address,
                                                            state/country, pages
                                                            you may view on our
                                                            site and in which
                                                            order you view them
                                                            in, along with other
                                                            similar
                                                            information.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Some of the ways
                                                            that you are
                                                            providing
                                                            information to us
                                                            includes, but is not
                                                            limited to:&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Use of our
                                                                Services
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Contacting us
                                                                for support or
                                                                requests through
                                                                Discord,
                                                                Twitter, email
                                                                or other means
                                                                of
                                                                communication.&nbsp;
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Participating in
                                                                contests
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Signing up for
                                                                distribution
                                                                lists such as
                                                                alerts,
                                                                newsletters or
                                                                other
                                                                informational
                                                                material
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                HOW YOUR
                                                                INFORMATION IS
                                                                USED
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Your information is
                                                            used for various
                                                            purposes and under
                                                            applicable law, with
                                                            certain legal bases
                                                            depending on the
                                                            different types of
                                                            information
                                                            collected about you
                                                            and directly from
                                                            you, including but
                                                            not limited to:
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Fulfillment of our
                                                            agreement with you
                                                            to:
                                                        </span>
                                                    </p>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Provide our
                                                                Services to you
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Respond to your
                                                                requests
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Provide more
                                                                efficient
                                                                customer service
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Contact you
                                                                about the
                                                                Services
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            For our legitimate
                                                            business interests
                                                            to:
                                                        </span>
                                                    </p>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Maintain
                                                                compliance with
                                                                any procedures,
                                                                laws and
                                                                regulations
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Defend, exercise
                                                                or establish our
                                                                legal rights
                                                                when necessary
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Improve our
                                                                Services
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Website security
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Technical issues
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Prevent misuse
                                                                of the website
                                                                and its services
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            To comply with our
                                                            legal obligations:
                                                        </span>
                                                    </p>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                For compliance,
                                                                regulatory and
                                                                auditing
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                For potential
                                                                legal claims
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            With your consent:
                                                        </span>
                                                    </p>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <ul
                                                            style={{
                                                                marginBottom: 0,
                                                                listStyleType:
                                                                    'undefined',
                                                                marginLeft: 0,
                                                            }}>
                                                            <li
                                                                style={{
                                                                    margin: 0,
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontSize:
                                                                        '15px',
                                                                    fontFamily:
                                                                        '"',
                                                                }}>
                                                                <span
                                                                    style={{
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    With your
                                                                    consent, we
                                                                    may collect
                                                                    and use your
                                                                    personal
                                                                    information.
                                                                    You may
                                                                    revoke
                                                                    consent at
                                                                    any time.
                                                                    Please note,
                                                                    that doing
                                                                    so may
                                                                    result in
                                                                    not being
                                                                    able to use
                                                                    certain
                                                                    features or
                                                                    services
                                                                    that may
                                                                    require the
                                                                    use or
                                                                    collection
                                                                    of your
                                                                    personal
                                                                    information.&nbsp;
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                INFORMATION
                                                                PROTECTION
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            We take every
                                                            precaution and
                                                            security measure to
                                                            ensure that your
                                                            personal information
                                                            is protected and not
                                                            misused, accessed by
                                                            an unauthorized
                                                            party, lost,
                                                            destroyed or
                                                            disclosed. Please
                                                            note that, although
                                                            we take all
                                                            reasonable steps to
                                                            protect your
                                                            information, just as
                                                            with all online
                                                            transactions,
                                                            nothing is ever
                                                            fully secure or free
                                                            of error. By using
                                                            our Services, you
                                                            are acknowledging
                                                            that you understand
                                                            the risks of
                                                            disclosing your
                                                            personal
                                                            information.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                CHILDREN’S
                                                                PRIVACY
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Our Website is not
                                                            for children under
                                                            the age of 14 and
                                                            our Services may
                                                            require a user to be
                                                            18 years of age or
                                                            older. We will take
                                                            appropriate measures
                                                            to delete personal
                                                            information to users
                                                            aged 14 and younger
                                                            who do not have a
                                                            valid parent or
                                                            guardian’s consent
                                                            to use our Website
                                                            and any applicable
                                                            Services as soon as
                                                            we are made aware of
                                                            it.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                COOKIES
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            We may use a
                                                            technology called
                                                            “cookies” or other
                                                            similar technologies
                                                            on our Website.
                                                            Cookies are small
                                                            blocks of data that
                                                            the Website can
                                                            place on your
                                                            browser. They can
                                                            remain on your
                                                            computer until they
                                                            either expire or you
                                                            delete them. You
                                                            have the ability to
                                                            accept, decline or
                                                            modify your browsers
                                                            settings to limit
                                                            the use of cookies.
                                                            Please note that if
                                                            you choose to
                                                            decline cookies,
                                                            there may be some
                                                            cases where you will
                                                            not be able to use
                                                            full functionality
                                                            of the Website and
                                                            Services.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                DATA RETENTION
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Your personal
                                                            information is not
                                                            retained longer than
                                                            necessary and in
                                                            accordance with
                                                            applicable
                                                            law.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                CHANGES TO OUR
                                                                PRIVACY POLICY
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Revisions to our
                                                            Privacy Policy can
                                                            be made at any time,
                                                            without notice,
                                                            unless legally
                                                            required. If
                                                            required by law, the
                                                            appropriate advance
                                                            notices will be
                                                            provided. All
                                                            changes are
                                                            effective
                                                            immediately. Your
                                                            use of our Services
                                                            confirms your
                                                            acceptance of our
                                                            Privacy
                                                            Policy.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:15pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '15pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={props.handleClose}
                                        ref={cancelButtonRef}>
                                        OK
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default PrivacyModal;
