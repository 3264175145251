/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

import emidasModelObj from '../../assets/models/emidas-logo.glb';

export default function Model(props) {
    const group = useRef();
    const { nodes } = useGLTF(emidasModelObj);
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group32170_1.geometry}
                material={nodes.Group32170_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group8650_1.geometry}
                material={nodes.Group8650_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group17811_1.geometry}
                material={nodes.Group17811_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group28005_1.geometry}
                material={nodes.Group28005_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group33246_1.geometry}
                material={nodes.Group33246_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Group14766_1.geometry}
                material={nodes.Group14766_1.material}
            />
        </group>
    );
}
