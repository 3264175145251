import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

function TermsModal(props) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={props.handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full z-50 max-h-[80vh]">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-roboto">
                                            <Dialog.Title
                                                as="span"
                                                className="text-6xl font-gnogen text-shadow font-medium text-gray-900 flex items-center justify-between mb-4">
                                                Terms & Conditions
                                                <div
                                                    className="flex items-center justify-center h-12 w-12 rounded-full"
                                                    onClick={props.handleClose}>
                                                    <XIcon
                                                        className="h-6 w-6 text-black"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                {/* THE FOLLOWING DIV WAS GENERATED FROM A WORD FILE WITH THOSE 3 STEPS:
                                                 *** 1- COPY THE WORD DOCUMENT CONTENT INTO THIS WEB TOOL: https://wordtohtml.net/
                                                 *** 2- COPY THE RESULT INOT THIS MINIFIER TOOL: https://beautifytools.com/html-minifier.php#:~:text=Online%20HTML%20Minifier%20removes%20all,absolute%20URLs%20to%20relative%20URLs.
                                                 *** 3- COPY THE RESULT INTO THIS HTML -> JSX CONVERTER: https://magic.reactjs.net/htmltojsx.htm
                                                 *** THE RESULT IS LARGER THAN SHOULD PROBABLY BE BUT TAKES WAY LESS TIME TO GENERATE THAN MANUALLY RE-TYPING THE DOC
                                                 */}
                                                <div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Gnogen Studios
                                                                Terms &amp;
                                                                Conditions
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Definitions
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Gnogen, Gnogen
                                                                Studios, “we”,
                                                                “us”, or “our”
                                                                -&nbsp;
                                                            </span>
                                                        </strong>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Gnogen Studios
                                                            and/or its
                                                            affiliates.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Gnogen Digital
                                                                Collectible
                                                                -&nbsp;
                                                            </span>
                                                        </strong>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            the image, artwork
                                                            or works of
                                                            authorship
                                                            associated with a
                                                            specific Gnogen
                                                            NFT.&nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                NFT -
                                                            </span>
                                                        </strong>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp; a blockchain
                                                            tracked non-fungible
                                                            token.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                TERMS &amp;
                                                                CONDITIONS
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            Gnogen Studios is a
                                                            collection of
                                                            digital artworks
                                                            (NFTs) running on
                                                            the Elrond network.
                                                            This website is only
                                                            an interface
                                                            allowing
                                                            participants to
                                                            exchange digital
                                                            collectibles. Users
                                                            are entirely
                                                            responsible for the
                                                            safety and
                                                            management of their
                                                            own private Elrond
                                                            wallets and
                                                            validating all
                                                            transactions and
                                                            contracts generated
                                                            by this website
                                                            before approval.
                                                            Furthermore, as the
                                                            Gnogen Studios smart
                                                            contract runs on the
                                                            Elrond network,
                                                            there is no ability
                                                            to undo, reverse, or
                                                            restore any
                                                            transactions.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            This website and its
                                                            connected services
                                                            are provided “as is”
                                                            and “as available”
                                                            without warranty of
                                                            any kind. By using
                                                            this website you are
                                                            accepting sole
                                                            responsibility for
                                                            any and all
                                                            transactions
                                                            involving Gnogen
                                                            Studios digital
                                                            collectibles.
                                                        </span>
                                                    </p>
                                                    <h2
                                                        style={{
                                                            marginTop: '.25in',
                                                            marginRight: 0,
                                                            marginBottom: '4pt',
                                                            marginLeft: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '21px',
                                                            fontFamily:
                                                                '"font-weight:400',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                OWNERSHIP
                                                            </span>
                                                        </strong>
                                                    </h2>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            i.{' '}
                                                            <strong>
                                                                You Own the NFT
                                                            </strong>
                                                            . Each{' '}
                                                            <span
                                                                style={{
                                                                    background:
                                                                        '#fff',
                                                                }}>
                                                                Gnogen Digital
                                                                Collectible
                                                            </span>{' '}
                                                            is an NFT on the
                                                            Elrond blockchain.
                                                            When you purchase an
                                                            NFT, you own the
                                                            underlying Gnogen{' '}
                                                            <span
                                                                style={{
                                                                    background:
                                                                        '#fff',
                                                                }}>
                                                                Digital
                                                                Collectible
                                                            </span>
                                                            , the Art,
                                                            completely.
                                                            Ownership of the NFT
                                                            is mediated entirely
                                                            by the Smart
                                                            Contract and the
                                                            Elrond Network: at
                                                            no point may we
                                                            seize, freeze, or
                                                            otherwise modify the
                                                            ownership of any
                                                            Gnogen NFT.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            ii.
                                                            <strong>
                                                                &nbsp;Personal
                                                                Use
                                                            </strong>
                                                            . Subject to your
                                                            continued compliance
                                                            with these Terms,
                                                            Gnogen Studios
                                                            grants you a
                                                            worldwide,
                                                            royalty-free license
                                                            to use, copy, and
                                                            display the
                                                            purchased Art, along
                                                            with any extensions
                                                            that you choose to
                                                            create or use,
                                                            solely for the
                                                            following purposes:
                                                            (i) for your own
                                                            personal,
                                                            non-commercial use;
                                                            (ii) as part of a
                                                            marketplace that
                                                            permits the purchase
                                                            and sale of your
                                                            Gnogen NFT, provided
                                                            that the marketplace
                                                            cryptographically
                                                            verifies each Gnogen
                                                            NFTs owner’s rights
                                                            to display the Art
                                                            for their Gnogen NFT
                                                            to ensure that only
                                                            the actual owner can
                                                            display the Art; or
                                                            (iii) as part of a
                                                            third party website
                                                            or application that
                                                            permits the
                                                            inclusion,
                                                            involvement, or
                                                            participation of
                                                            your Gnogen NFT,
                                                            provided that the
                                                            website/application
                                                            cryptographically
                                                            verifies each Gnogen
                                                            NFT owner’s rights
                                                            to display the Art
                                                            for their Gnogen NFT
                                                            to ensure that only
                                                            the actual owner can
                                                            display the Art, and
                                                            provided that the
                                                            Art is no longer
                                                            visible once the
                                                            owner of the Gnogen
                                                            NFT leaves the
                                                            website/application.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            iii.{' '}
                                                            <strong>
                                                                Commercial Use
                                                            </strong>
                                                            . Subject to your
                                                            continued compliance
                                                            with these Terms,
                                                            Gnogen Studios
                                                            grants you an
                                                            unlimited, worldwide
                                                            license to use,
                                                            copy, and display
                                                            the purchased Art
                                                            for the purpose of
                                                            creating derivative
                                                            works based upon the
                                                            Art (“Commercial
                                                            Use”). Examples of
                                                            such Commercial Use
                                                            would e.g. be the
                                                            use of the Art to
                                                            produce and sell
                                                            merchandise products
                                                            (T-Shirts etc.)
                                                            displaying copies of
                                                            the Art. For the
                                                            sake of clarity,
                                                            nothing in this
                                                            Section will be
                                                            deemed to restrict
                                                            you from (i) owning
                                                            or operating a
                                                            marketplace that
                                                            permits the use and
                                                            sale of Gnogen NFTs
                                                            generally, provided
                                                            that the marketplace
                                                            cryptographically
                                                            verifies each Gnogen
                                                            NFTs owner’s rights
                                                            to display the Art
                                                            for their Gnogen NFT
                                                            to ensure that only
                                                            the actual owner can
                                                            display the Art;
                                                            (ii) owning or
                                                            operating a third
                                                            party website or
                                                            application that
                                                            permits the
                                                            inclusion,
                                                            involvement, or
                                                            participation of
                                                            Gnogen NFTs
                                                            generally, provided
                                                            that the third party
                                                            website or
                                                            application
                                                            cryptographically
                                                            verifies each Gnogen
                                                            NFTs owner’s rights
                                                            to display the Art
                                                            for their Gnogen NFT
                                                            to ensure that only
                                                            the actual owner can
                                                            display the Art, and
                                                            provided that the
                                                            Art is no longer
                                                            visible once the
                                                            owner of the
                                                            purchased Gnogen NFT
                                                            leaves the
                                                            website/application;
                                                            or (iii) earning
                                                            revenue from any of
                                                            the foregoing.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Terms of Use
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                PLEASE READ
                                                                THESE TERMS OF
                                                                USE CAREFULLY
                                                                BEFORE YOU START
                                                                TO USE THE
                                                                WEBSITE BECAUSE
                                                                THEY AFFECT YOUR
                                                                LEGAL RIGHTS AND
                                                                OBLIGATIONS
                                                                INCLUDING,
                                                                WITHOUT
                                                                LIMITATION,
                                                                WAIVERS OF
                                                                RIGHTS,
                                                                LIMITATIONS OF
                                                                LIABILITY, AND
                                                                YOUR INDEMNITY
                                                                TO US. THESE
                                                                TERMS OF USE
                                                                ALSO REQUIRE
                                                                THAT YOU USE
                                                                ARBITRATION ON
                                                                AN INDIVIDUAL
                                                                BASIS TO RESOLVE
                                                                DISPUTES, RATHER
                                                                THAN COURTS,
                                                                JURY TRIALS, OR
                                                                CLASS ACTIONS,
                                                                AND LIMITS THE
                                                                REMEDIES
                                                                AVAILABLE TO YOU
                                                                IN THE EVENT OF
                                                                A DISPUTE.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Acceptance
                                                                    of the
                                                                    Gnogen
                                                                    Website
                                                                    Terms of Use
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                These terms of
                                                                use are entered
                                                                into by and
                                                                between you and
                                                                Gnogen Studios
                                                                (“
                                                                <strong>
                                                                    we
                                                                </strong>
                                                                ” and “
                                                                <strong>
                                                                    us
                                                                </strong>
                                                                ”). The
                                                                following terms
                                                                and conditions,
                                                                together with
                                                                any documents
                                                                they expressly
                                                                incorporate by
                                                                reference
                                                                (collectively, “
                                                                <strong>
                                                                    Terms of Use
                                                                </strong>
                                                                ” and “
                                                                <strong>
                                                                    Agreement
                                                                </strong>
                                                                ”), govern your
                                                                access to and
                                                                use of the&nbsp;
                                                            </span>
                                                            <a href="https://hro.gg/">
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                        color: 'windowtext',
                                                                        textDecoration:
                                                                            'none',
                                                                    }}>
                                                                    www.Gnogen.
                                                                </span>
                                                            </a>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                io website,
                                                                including any
                                                                content,
                                                                functionality,
                                                                and services
                                                                offered on or
                                                                through the
                                                                website (the “
                                                                <strong>
                                                                    site
                                                                </strong>
                                                                “).
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    By using the
                                                                    site, you
                                                                    accept and
                                                                    agree to be
                                                                    bound and
                                                                    abide by
                                                                    these Terms
                                                                    of Use and
                                                                    our&nbsp;
                                                                </span>
                                                            </strong>
                                                            <a href="https://hro.gg/privacy-policy/">
                                                                <strong>
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                '16px',
                                                                            lineHeight:
                                                                                '115%',
                                                                            fontFamily:
                                                                                'Roboto',
                                                                            color: 'windowtext',
                                                                            textDecoration:
                                                                                'none',
                                                                        }}>
                                                                        Privacy
                                                                        Policy
                                                                    </span>
                                                                </strong>
                                                            </a>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    &nbsp;and
                                                                    incorporated
                                                                    herein by
                                                                    reference
                                                                </span>
                                                            </strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                . If you do not
                                                                want to agree to
                                                                these Terms of
                                                                Use or the
                                                                Privacy Policy,
                                                                you must not
                                                                access or use
                                                                the site.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                The site is
                                                                offered and
                                                                available to
                                                                users who are 14
                                                                years of age or
                                                                older. By using
                                                                the site, you
                                                                represent and
                                                                warrant that you
                                                                are at least 14
                                                                years of age and
                                                                are otherwise
                                                                legally
                                                                competent to
                                                                enter into this
                                                                Agreement. If
                                                                you are less
                                                                than 14 years
                                                                old or are
                                                                otherwise not
                                                                legally
                                                                competent to
                                                                enter into this
                                                                Agreement, you
                                                                must not access
                                                                or use the site.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Changes to
                                                                    the Terms of
                                                                    Use
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                At our sole
                                                                discretion, from
                                                                time to time, we
                                                                may revise and
                                                                update these
                                                                Terms of Use.
                                                                Changes apply to
                                                                all access to
                                                                and use of the
                                                                site thereafter
                                                                and are
                                                                effective
                                                                immediately when
                                                                we post them. By
                                                                continuing to
                                                                use the site
                                                                after any
                                                                revisions of the
                                                                Terms of Use,
                                                                means that you
                                                                accept and agree
                                                                to the changes.
                                                                Since these
                                                                terms are
                                                                binding to you,
                                                                you are expected
                                                                to check this
                                                                page frequently
                                                                so you are aware
                                                                of any changes.
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Accessing
                                                                    the Site
                                                                </span>
                                                            </strong>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                We reserve the
                                                                right to
                                                                withdraw or
                                                                amend the site,
                                                                and any service
                                                                or material we
                                                                provide on the
                                                                site, at our
                                                                sole discretion
                                                                and without
                                                                notice. We will
                                                                not be liable if
                                                                for any reason
                                                                all or any part
                                                                of the site is
                                                                unavailable at
                                                                any time or for
                                                                any period. From
                                                                time to time, we
                                                                may restrict
                                                                access to some
                                                                parts of the
                                                                site or the
                                                                entire site. You
                                                                are responsible
                                                                for making all
                                                                arrangements
                                                                necessary for
                                                                you to have
                                                                access to the
                                                                site. You are
                                                                also responsible
                                                                for ensuring
                                                                that all persons
                                                                who access the
                                                                site through
                                                                your internet
                                                                connection are
                                                                aware of these
                                                                Terms of Use and
                                                                comply with
                                                                them. To join
                                                                our mailing
                                                                lists or access
                                                                other resources
                                                                offered on the
                                                                site, you may be
                                                                asked to provide
                                                                certain
                                                                information. It
                                                                is a condition
                                                                of your use of
                                                                the site that
                                                                all the
                                                                information you
                                                                provide is
                                                                correct,
                                                                current, and
                                                                complete. You
                                                                agree that all
                                                                information you
                                                                provide to us in
                                                                connection with
                                                                the site,
                                                                including, but
                                                                not limited to,
                                                                through the use
                                                                of any
                                                                interactive
                                                                features on the
                                                                site, is
                                                                governed by our
                                                                Privacy Policy,
                                                                and you consent
                                                                to all actions
                                                                we take with
                                                                respect to your
                                                                information
                                                                consistent with
                                                                our Privacy
                                                                Policy.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Intellectual
                                                                    Property
                                                                    Rights
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                The site and its
                                                                entire contents,
                                                                features, and
                                                                functionality
                                                                (including
                                                                without
                                                                limitation all
                                                                information,
                                                                software, text,
                                                                displays,
                                                                images, video,
                                                                audio, the
                                                                design,
                                                                selection, and
                                                                arrangement
                                                                thereof) are
                                                                owned by us, our
                                                                affiliates, our
                                                                or their
                                                                licensors, or
                                                                other providers
                                                                of such
                                                                material, and
                                                                are protected by
                                                                United States
                                                                and
                                                                international
                                                                copyright,
                                                                trademark,
                                                                patent, trade
                                                                secret, and
                                                                other
                                                                intellectual
                                                                property or
                                                                proprietary
                                                                rights laws.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                These Terms of
                                                                Use permit you
                                                                to use the site
                                                                for your
                                                                personal,
                                                                non-commercial
                                                                use only. You
                                                                must not
                                                                reproduce,
                                                                distribute,
                                                                modify, create
                                                                derivative works
                                                                of, publicly
                                                                display,
                                                                publicly
                                                                perform,
                                                                republish,
                                                                download, store,
                                                                or transmit any
                                                                of the material
                                                                on the site,
                                                                except as
                                                                follows:
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Your computer
                                                                may temporarily
                                                                store copies of
                                                                such materials
                                                                in RAM
                                                                incidental to
                                                                your accessing
                                                                and viewing
                                                                those materials.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You may store
                                                                files that are
                                                                automatically
                                                                cached by your
                                                                Web browser for
                                                                display
                                                                enhancement
                                                                purposes.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You may print or
                                                                download one
                                                                copy of a
                                                                reasonable
                                                                number of pages
                                                                of the Website
                                                                for your own
                                                                personal,
                                                                non-commercial
                                                                use and not for
                                                                further
                                                                reproduction,
                                                                publication, or
                                                                distribution.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                If we provide
                                                                desktop, mobile,
                                                                or other
                                                                applications for
                                                                download, you
                                                                may download a
                                                                single copy to
                                                                your computer or
                                                                mobile device
                                                                solely for your
                                                                own personal,
                                                                non-commercial
                                                                use, provided
                                                                you agree to be
                                                                bound by our end
                                                                user license
                                                                agreement for
                                                                such
                                                                applications.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                If we provide
                                                                social media
                                                                features with
                                                                certain content,
                                                                you may take
                                                                such actions as
                                                                are enabled by
                                                                such features.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            You must not:
                                                            <ul
                                                                style={{
                                                                    listStyleType:
                                                                        'undefined',
                                                                    marginLeft: 0,
                                                                }}>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                '130%',
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Modify
                                                                        copies
                                                                        of any
                                                                        materials
                                                                        from
                                                                        this
                                                                        site.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                '130%',
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Use any
                                                                        illustrations,
                                                                        photographs,
                                                                        video or
                                                                        audio
                                                                        sequences,
                                                                        or any
                                                                        graphics
                                                                        separately
                                                                        from the
                                                                        accompanying
                                                                        text.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                '130%',
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Delete
                                                                        or alter
                                                                        any
                                                                        copyright,
                                                                        trademark,
                                                                        or other
                                                                        proprietary
                                                                        rights
                                                                        notices
                                                                        from
                                                                        copies
                                                                        of
                                                                        materials
                                                                        from
                                                                        this
                                                                        site.
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>

                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You must not
                                                                access or use
                                                                for any
                                                                commercial
                                                                purposes any
                                                                part of the site
                                                                or any services
                                                                or materials
                                                                available
                                                                through the
                                                                Website. The
                                                                trademarks,
                                                                names, logos,
                                                                product and
                                                                service names,
                                                                designs, and
                                                                slogans on the
                                                                site are
                                                                trademarks of
                                                                Gnogen Studios,
                                                                other affiliates
                                                                of Gnogen
                                                                Studios, their
                                                                licensors, or
                                                                other providers.
                                                                You must not use
                                                                any mark without
                                                                the prior
                                                                written
                                                                permission of
                                                                its respective
                                                                owner.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                If you print,
                                                                copy, modify,
                                                                download, or
                                                                otherwise use or
                                                                provide any
                                                                other person
                                                                with access to
                                                                any part of the
                                                                site in breach
                                                                of the Terms of
                                                                Use, your right
                                                                to use the site
                                                                will stop
                                                                immediately and
                                                                you must, at our
                                                                option, return
                                                                or destroy any
                                                                copies of the
                                                                materials you
                                                                have made. No
                                                                right, title, or
                                                                interest in or
                                                                to the site or
                                                                any content on
                                                                the site is
                                                                transferred to
                                                                you, and all
                                                                rights not
                                                                expressly
                                                                granted are
                                                                reserved by us.
                                                                Any use of the
                                                                site not
                                                                expressly
                                                                permitted by
                                                                these Terms of
                                                                Use is a breach
                                                                of these Terms
                                                                of Use and may
                                                                violate
                                                                copyright,
                                                                trademark, and
                                                                other laws.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Prohibited
                                                                    Uses
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You may use the
                                                                site only for
                                                                lawful purposes
                                                                and in
                                                                accordance with
                                                                these Terms of
                                                                Use. You agree
                                                                not to use the
                                                                site:
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <ul
                                                        className="list-decimal"
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                In any way that
                                                                violates any
                                                                applicable
                                                                federal, state,
                                                                local, or
                                                                international
                                                                law or
                                                                regulation
                                                                (including,
                                                                without
                                                                limitation, any
                                                                laws regarding
                                                                the export of
                                                                data or software
                                                                to and from the
                                                                US or other
                                                                countries).
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                For exploiting,
                                                                harming, or
                                                                attempting to
                                                                exploit or harm
                                                                minors in any
                                                                way by exposing
                                                                them to
                                                                inappropriate
                                                                content, asking
                                                                for personally
                                                                identifiable
                                                                information, or
                                                                otherwise.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                To transmit, or
                                                                procure the
                                                                sending of, any
                                                                advertising or
                                                                promotional
                                                                material,
                                                                including any
                                                                “junk mail,”
                                                                “chain letter,”
                                                                “spam,” or any
                                                                other similar
                                                                solicitation.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                To impersonate
                                                                or attempt to
                                                                impersonate
                                                                Gnogen Studios,
                                                                its affiliates,
                                                                their licensors,
                                                                or any employee
                                                                of Gnogen
                                                                Studios, its
                                                                affiliates, or
                                                                their licensors,
                                                                or any other
                                                                person or entity
                                                                (including,
                                                                without
                                                                limitation, by
                                                                using email
                                                                addresses or
                                                                screen names
                                                                associated with
                                                                any of the
                                                                foregoing).
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;To engage
                                                                in any other
                                                                conduct that
                                                                restricts or
                                                                inhibits
                                                                anyone’s use or
                                                                enjoyment of the
                                                                site, or which,
                                                                as determined by
                                                                us, may harm us
                                                                or users of the
                                                                site, or expose
                                                                them to
                                                                liability.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            Additionally, you
                                                            agree not to:
                                                            <ul
                                                                style={{
                                                                    listStyleType:
                                                                        'undefined',
                                                                    marginLeft: 0,
                                                                }}>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Use the
                                                                        site in
                                                                        any
                                                                        manner
                                                                        that
                                                                        could
                                                                        disable,
                                                                        overburden,
                                                                        damage,
                                                                        or
                                                                        impair
                                                                        the site
                                                                        or
                                                                        interfere
                                                                        with any
                                                                        other
                                                                        party’s
                                                                        use of
                                                                        the
                                                                        site,
                                                                        including
                                                                        their
                                                                        ability
                                                                        to
                                                                        engage
                                                                        in real
                                                                        time
                                                                        activities
                                                                        through
                                                                        the
                                                                        site.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Use any
                                                                        robot,
                                                                        spider,
                                                                        or other
                                                                        automatic
                                                                        device,
                                                                        process,
                                                                        or means
                                                                        to
                                                                        access
                                                                        the site
                                                                        for any
                                                                        purpose,
                                                                        including
                                                                        monitoring
                                                                        or
                                                                        copying
                                                                        any of
                                                                        the
                                                                        material
                                                                        on the
                                                                        site.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Use any
                                                                        manual
                                                                        process
                                                                        to
                                                                        monitor
                                                                        or copy
                                                                        any of
                                                                        the
                                                                        material
                                                                        on the
                                                                        site, or
                                                                        for any
                                                                        other
                                                                        purpose
                                                                        not
                                                                        expressly
                                                                        authorized
                                                                        in these
                                                                        Terms of
                                                                        Use,
                                                                        without
                                                                        our
                                                                        prior
                                                                        written
                                                                        consent.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Use any
                                                                        device,
                                                                        software,
                                                                        or
                                                                        routine
                                                                        that
                                                                        interferes
                                                                        with the
                                                                        proper
                                                                        working
                                                                        of the
                                                                        site.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Introduce
                                                                        any
                                                                        viruses,
                                                                        Trojan
                                                                        horses,
                                                                        worms,
                                                                        logic
                                                                        bombs,
                                                                        or other
                                                                        material
                                                                        that is
                                                                        malicious
                                                                        or
                                                                        technologically
                                                                        harmful.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Attempt
                                                                        to gain
                                                                        unauthorized
                                                                        access
                                                                        to,
                                                                        interfere
                                                                        with,
                                                                        damage,
                                                                        or
                                                                        disrupt
                                                                        any
                                                                        parts of
                                                                        the
                                                                        site,
                                                                        the
                                                                        server
                                                                        on which
                                                                        the site
                                                                        is
                                                                        stored,
                                                                        or any
                                                                        server,
                                                                        computer,
                                                                        or
                                                                        database
                                                                        connected
                                                                        to the
                                                                        site.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Attack
                                                                        the site
                                                                        via a
                                                                        denial-of-service
                                                                        attack
                                                                        or a
                                                                        distributed
                                                                        denial-of-service
                                                                        attack.
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{
                                                                            fontFamily:
                                                                                'Roboto',
                                                                        }}>
                                                                        Otherwise
                                                                        attempt
                                                                        to
                                                                        interfere
                                                                        with the
                                                                        proper
                                                                        working
                                                                        of the
                                                                        site.
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Reliance on
                                                                    Information
                                                                    Posted
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                The information
                                                                presented on or
                                                                through the site
                                                                is made
                                                                available solely
                                                                for general
                                                                information
                                                                purposes. We do
                                                                not warrant the
                                                                accuracy,
                                                                completeness, or
                                                                usefulness of
                                                                this
                                                                information. Any
                                                                reliance you
                                                                place on such
                                                                information is
                                                                strictly at your
                                                                own risk. We
                                                                disclaim all
                                                                liability and
                                                                responsibility
                                                                arising from any
                                                                reliance placed
                                                                on such
                                                                materials by you
                                                                or any other
                                                                visitor to the
                                                                Website, or by
                                                                anyone who may
                                                                be informed of
                                                                any of its
                                                                contents.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                The site
                                                                includes content
                                                                provided by
                                                                third parties,
                                                                including
                                                                materials
                                                                provided by
                                                                third-party
                                                                licensors. All
                                                                statements and
                                                                opinions
                                                                expressed in
                                                                these materials,
                                                                and all articles
                                                                and responses to
                                                                questions and
                                                                other content,
                                                                other than the
                                                                content provided
                                                                by Gnogen
                                                                Studios, are
                                                                solely the
                                                                opinions and the
                                                                responsibility
                                                                of the person or
                                                                entity providing
                                                                those materials.
                                                                Third-party
                                                                materials do not
                                                                necessarily
                                                                reflect the
                                                                opinion of
                                                                Gnogen Studios.
                                                                We are not
                                                                responsible, or
                                                                liable to you or
                                                                any third party,
                                                                for the content
                                                                or accuracy of
                                                                any materials
                                                                provided by any
                                                                third parties.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Changes to
                                                                    the Website
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Updates to the
                                                                site may happen
                                                                from time to
                                                                time. This does
                                                                not indicate
                                                                that content on
                                                                the site is
                                                                complete or up
                                                                to date. There
                                                                may be times
                                                                where material
                                                                on our site is
                                                                out of date. We
                                                                are under no
                                                                obligation to
                                                                update that
                                                                material or
                                                                update that
                                                                material within
                                                                a given amount
                                                                of time.&nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Information
                                                                    About You
                                                                    and Your
                                                                    Visits to
                                                                    the Website
                                                                </span>
                                                            </strong>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Any information
                                                                that we collect
                                                                on the site is
                                                                subject to our
                                                                Privacy Policy.
                                                                You are
                                                                consenting to
                                                                all actions
                                                                taken by us in
                                                                respect to your
                                                                information in
                                                                compliance with
                                                                the Privacy
                                                                Policy by using
                                                                the site.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    &nbsp;
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Linking to
                                                                    the Website
                                                                    and Social
                                                                    Media
                                                                    Features
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Linking to our
                                                                homepage is
                                                                permitted,
                                                                provided you do
                                                                so in a way that
                                                                is legal and
                                                                fair and does
                                                                not damage our
                                                                reputation or
                                                                take advantage
                                                                of it. You may
                                                                not suggest in
                                                                any form that
                                                                you are
                                                                associated with
                                                                Gnogen Studios,
                                                                have approval or
                                                                any type of
                                                                endorsement or
                                                                partnership with
                                                                Gnogen Studios
                                                                without our
                                                                written
                                                                consent.&nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You must not:
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <ul
                                                        style={{
                                                            listStyleType:
                                                                'undefined',
                                                            marginLeft: 0,
                                                            marginBottom:
                                                                '1rem',
                                                        }}>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Establish a link
                                                                from any website
                                                                that is not
                                                                owned by you.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Cause the site
                                                                or portions of
                                                                it to be
                                                                displayed on, or
                                                                appear to be
                                                                displayed by,
                                                                any other site,
                                                                for example,
                                                                framing, deep
                                                                linking, or
                                                                in-line linking.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Link to any part
                                                                of the site
                                                                other than the
                                                                homepage except
                                                                with our written
                                                                consent.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Otherwise take
                                                                any action with
                                                                respect to the
                                                                materials on the
                                                                site that is
                                                                inconsistent
                                                                with any other
                                                                provision of
                                                                these Terms of
                                                                Use.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You agree to
                                                                cooperate with
                                                                us in causing
                                                                any unauthorized
                                                                framing or
                                                                linking
                                                                immediately to
                                                                stop. We reserve
                                                                the right to
                                                                withdraw linking
                                                                permission
                                                                without notice.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}></p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                At our
                                                                discretion, we
                                                                may disable any
                                                                or all social
                                                                media features
                                                                and links
                                                                without prior
                                                                notice.&nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    'normal',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Links from
                                                                    the Website
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                There may be
                                                                links on the
                                                                site that are
                                                                not directly
                                                                managed or owned
                                                                by Gnogen
                                                                Studios. The
                                                                links may be
                                                                resources to
                                                                other sites
                                                                provided by our
                                                                licensors or
                                                                other third
                                                                parties. These
                                                                are provided to
                                                                you for your
                                                                convenience
                                                                only. This
                                                                includes, but is
                                                                not limited to
                                                                sponsored links,
                                                                advertisements
                                                                or advertisement
                                                                banners. We do
                                                                not accept any
                                                                responsibility
                                                                for the content
                                                                of the links, as
                                                                we have no
                                                                control over
                                                                them. By
                                                                accessing these
                                                                links, you do so
                                                                at your own risk
                                                                and in subject
                                                                to the terms and
                                                                conditions of
                                                                those sites.
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '150%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Limitation
                                                                    on Liability
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                TO THE FULLEST
                                                                EXTENT PROVIDED
                                                                BY LAW, IN NO
                                                                EVENT WILL THE
                                                                COLLECTIVE
                                                                LIABILITY OF
                                                                GNOGEN STUDIOS,
                                                                ITS AFFILIATES,
                                                                THEIR LICENSORS
                                                                AND SERVICE
                                                                PROVIDERS, AND
                                                                ANY PERSON
                                                                ASSOCIATED WITH
                                                                ANY OF THEM, TO
                                                                YOU (REGARDLESS
                                                                OF THE FORM OF
                                                                ACTION, WHETHER
                                                                IN CONTRACT,
                                                                TORT, OR
                                                                OTHERWISE)
                                                                EXCEED THE
                                                                AMOUNT OF $100.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                THE FOREGOING
                                                                DOES NOT AFFECT
                                                                ANY LIABILITY
                                                                THAT CANNOT BE
                                                                EXCLUDED OR
                                                                LIMITED UNDER
                                                                APPLICABLE LAW.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}></h2>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '150%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Indemnification
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You agree to
                                                                defend,
                                                                indemnify, and
                                                                hold harmless
                                                                Gnogen Studios,
                                                                its employees,
                                                                contractors,
                                                                agents,
                                                                successors,
                                                                suppliers,
                                                                affiliates,
                                                                their licensors
                                                                and service
                                                                providers, and
                                                                each of their
                                                                respective
                                                                officers, from
                                                                and against any
                                                                claims,
                                                                liabilities,
                                                                damages,
                                                                judgments,
                                                                awards, losses,
                                                                costs, expenses,
                                                                or fees
                                                                (including
                                                                reasonable
                                                                attorneys’ fees)
                                                                that arise out
                                                                of or relating
                                                                to your
                                                                violation of
                                                                these Terms of
                                                                Use or your use
                                                                of the site,
                                                                including, but
                                                                not limited to,
                                                                any use of the
                                                                site’s services,
                                                                products and
                                                                content other
                                                                than as
                                                                expressly
                                                                authorized in
                                                                these Terms of
                                                                Use, or your use
                                                                of any
                                                                information
                                                                obtained from
                                                                the site.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '150%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Waiver and
                                                                    Severability
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                No waiver by
                                                                Gnogen Studios
                                                                of any term or
                                                                condition set
                                                                out in these
                                                                Terms of Use
                                                                shall be deemed
                                                                a further or
                                                                continuing
                                                                waiver of such
                                                                term or
                                                                condition or a
                                                                waiver of any
                                                                other term or
                                                                condition, and
                                                                any failure of
                                                                Gnogen Studios
                                                                to assert a
                                                                right or
                                                                provision under
                                                                these Terms of
                                                                Use shall not
                                                                constitute a
                                                                waiver of such
                                                                right or
                                                                provision.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                If any provision
                                                                of these Terms
                                                                of Use is held
                                                                by a court or
                                                                other tribunal
                                                                of competent
                                                                jurisdiction to
                                                                be invalid,
                                                                illegal, or
                                                                unenforceable
                                                                for any reason,
                                                                such provision
                                                                shall be
                                                                eliminated or
                                                                limited to the
                                                                minimum extent
                                                                such that the
                                                                remaining
                                                                provisions of
                                                                the Terms of Use
                                                                will continue in
                                                                full force and
                                                                effect.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '150%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Entire
                                                                    Agreement
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                The Terms of Use
                                                                and our Privacy
                                                                Policy
                                                                constitute the
                                                                sole and entire
                                                                agreement
                                                                between you and
                                                                Gnogen Studios
                                                                regarding the
                                                                site and
                                                                supersede all
                                                                prior and
                                                                contemporaneous
                                                                understandings,
                                                                agreements,
                                                                representations,
                                                                and warranties,
                                                                both written and
                                                                oral, regarding
                                                                the site. For
                                                                purposes of
                                                                clarity,
                                                                however, any
                                                                separate
                                                                agreements
                                                                between you and
                                                                Gnogen Studios,
                                                                including
                                                                without
                                                                limitation any
                                                                terms and
                                                                conditions
                                                                governing any
                                                                other website or
                                                                governing any
                                                                digital asset
                                                                offered by
                                                                Gnogen Studios
                                                                or by its
                                                                affiliate, shall
                                                                remain in full
                                                                force and effect
                                                                according to
                                                                their terms.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 7pt 0',
                                                        }}>
                                                        <h2
                                                            style={{
                                                                marginTop:
                                                                    '.25in',
                                                                marginRight: 0,
                                                                marginBottom:
                                                                    '6pt',
                                                                marginLeft: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '21px',
                                                                fontFamily:
                                                                    '"font-weight:400',
                                                                margin: 0,
                                                                border: 'none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '150%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    Your
                                                                    Comments and
                                                                    Concerns
                                                                </span>
                                                            </strong>
                                                        </h2>
                                                    </div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '150%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '150%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            All notices of
                                                            copyright
                                                            infringement claims
                                                            should be sent to
                                                            the copyright agent
                                                            designated above in
                                                            the section entitled
                                                            “Copyright
                                                            Infringement” in the
                                                            manner and by the
                                                            means set out
                                                            therein.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '150%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '150%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '150%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '150%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            All other feedback,
                                                            comments, requests
                                                            for technical
                                                            support, and other
                                                            communications
                                                            relating to the site
                                                            should be directed
                                                            to:
                                                            support@gnogen.io.
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: '.5in',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily: '"',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"margin-top:12pt',
                                                            marginRight: 0,
                                                            marginBottom:
                                                                '12pt',
                                                            marginLeft: 0,
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        '"',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={props.handleClose}
                                        ref={cancelButtonRef}>
                                        OK
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default TermsModal;
