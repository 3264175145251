import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { XCircleIcon, FilterIcon } from '@heroicons/react/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import useGetListings from '../../hooks/useGetListings';
import Localization from '../../utils/localization';
import Collapsible from '../../components/Collapsible';
import Checkbox from '../../components/Checkbox';
import Divider from '../../components/Divider';
import Drawer from '../../components/Drawer';
import NftPreview from '../../components/NFTPreview';
import GnogenImage from '../../components/GnogenImage';
import xoxnoMarket from '../../assets/icons/logo-xoxno.png';
import deadrareMarket from '../../assets/icons/logo-deadrare.webp';
import { ReactComponent as GeneralFilterIcon } from '../../assets/icons/nftFilters/filter-general.svg';
import { collections } from '../../utils/config';

const XOXNO = 'XOXNO';
const DEADRARE = 'DEADRARE';
const marketplaceFilters = [XOXNO, DEADRARE];
const marketplaceMappings = { XO: XOXNO, DR: DEADRARE };

const NFTS_PER_LOAD = 20;

const CollectionNft = (props) => {
    const navigate = useNavigate();
    let { id } = useParams(); // Unpacking and retrieve id
    const [collection, setCollection] = React.useState(null);
    const [displayedNfts, setDisplayedNfts] = useState([]);
    const [filteredAllNfts, setFilteredAllNfts] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [salesFilters, setSalesFilters] = useState([]);
    const [nftPreview, setNftPreview] = useState(null);
    const [previewingNft, setPreviewingNft] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [nftListings, setNftListings] = useState(null);
    const fetchNftListings = useGetListings(collection?.collectionId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Parse through the collection items and retrieve all unique selectors
        collections.forEach((col, index) => {
            if (col.id === id) {
                if (col.comingSoon) {
                    navigate('/collections');
                } else {
                    setCollection(col);
                }
                return;
            }
        });
    }, [setCollection, id]);

    useEffect(() => {
        if (fetchNftListings.isSuccess) {
            if (fetchNftListings.data) {
                setNftListings(fetchNftListings.data);
            }
        }
    }, [fetchNftListings.isSuccess, fetchNftListings.data]);

    useEffect(() => {
        if (!collection) return;
        async function filterData() {
            // Filter Nft data
            let newFilteredData = [];
            const filterEntries = Object.entries(selectedFilters);
            if (filterEntries.length === 0 && salesFilters.length === 0) {
                newFilteredData = collection.data;
            } else {
                newFilteredData = collection.data.filter((nft, index) => {
                    if (salesFilters.length > 0 && nftListings) {
                        if (
                            nftListings[nft.id] &&
                            salesFilters.indexOf(
                                marketplaceMappings[
                                    nftListings[nft.id].saleInfoNft.marketplace
                                ]
                            ) >= 0
                        ) {
                            // can keep going
                        } else {
                            return false;
                        }
                    }
                    if (filterEntries.length > 0) {
                        let propIt, attIt;
                        for (
                            propIt = 0;
                            propIt < filterEntries.length;
                            propIt++
                        ) {
                            const [filterKey, options] = filterEntries[propIt];
                            for (attIt = 0; attIt < options.length; attIt++) {
                                const filterOption = options[attIt];
                                if (
                                    nft.attributes[filterKey] !==
                                        filterOption &&
                                    !nft.attributes[filterKey]
                                        .toUpperCase()
                                        .includes(filterOption)
                                ) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                });
            }
            setFilteredAllNfts(newFilteredData);
            setDisplayedNfts(newFilteredData.slice(0, NFTS_PER_LOAD));
        }
        filterData();
    }, [collection, selectedFilters, salesFilters, setDisplayedNfts]);

    const filterChange = (key, option, value) => {
        let newSelectedFilters = { ...selectedFilters };
        if (!newSelectedFilters[key] && value) {
            newSelectedFilters[key] = [option];
        } else {
            if (!value) {
                const index = newSelectedFilters[key].indexOf(option);
                if (index > -1) {
                    newSelectedFilters[key].splice(index, 1); // 2nd parameter means remove one item only
                }
                if (newSelectedFilters[key].length === 0) {
                    delete newSelectedFilters[key];
                }
            } else {
                newSelectedFilters[key].push(option);
            }
        }

        setSelectedFilters(newSelectedFilters);
    };

    const fetchMore = () => {
        setDisplayedNfts(
            filteredAllNfts.slice(0, displayedNfts.length + NFTS_PER_LOAD)
        );
    };

    const filterSectionTitle = (filterKey, opened) => {
        let FilterLogo = collection.filterIcons[filterKey];
        return (
            <div className="flex w-full items-center pb-4">
                {collection.filterIcons[filterKey] ? (
                    <FilterLogo className="w-6 h-6" fill={'white'} />
                ) : (
                    <GeneralFilterIcon className="w-6 h-6" fill={'white'} />
                )}
                <span className="mx-4 font-roboto font-bold text-lg">
                    {filterKey}
                </span>
                <div
                    className={`ml-auto w-0 h-0 animated-transform ${
                        opened ? 'rotate-180' : ''
                    }`}
                    style={{
                        borderLeft: '8px solid transparent',
                        borderRight: '8px solid transparent',
                        borderTop: '8px solid #fff',
                    }}
                />
            </div>
        );
    };
    const MarketAvailability = ({ nft, index }) => {
        if (!nftListings || !nftListings[nft.id]) {
            return null;
        }
        const nftMarketplace = nftListings[nft.id].saleInfoNft.marketplace;
        let marketIcon = null;
        if (marketplaceMappings[nftMarketplace] === XOXNO)
            marketIcon = xoxnoMarket;
        else if (marketplaceMappings[nftMarketplace] === DEADRARE)
            marketIcon = deadrareMarket;
        else console.log('Unknown marketplace: ' + nftMarketplace);
        return (
            <div className="absolute w-12 h-12 top-2 right-2 rounded-3xl bg-gnogen-gray p-2 border border-white z-10">
                <img src={marketIcon} />
            </div>
        );
    };

    const NftCard = ({ nft, index }) => {
        return (
            <div
                className="flex flex-col cursor-pointer group relative"
                key={index}
                onClick={() => {
                    setNftPreview(nft);
                    setPreviewingNft(true);
                }}>
                <MarketAvailability nft={nft} index={index} />
                <div className="w-full aspect-square">
                    <GnogenImage
                        className="rounded-xl animated-box-shadow shadow-transparent shadow-md group-hover:shadow-gnogen-gold max-w-full aspect-square"
                        src={nft.url}
                        alt={nft.name}
                    />
                </div>
                <div className="flex pt-4 justify-between items-center">
                    {collection.seal && (
                        <img
                            className="w-12 sm:w-16 p-2"
                            src={collection.seal}
                            alt={'SEAL'}
                        />
                    )}
                    <div className="flex flex-col font-roboto justify-center items-end ml-auto">
                        <span className="text-sm font-light">{nft.name}</span>
                        <span className="">
                            {Localization.collections.rank}&nbsp;
                            <span className="font-bold">{nft.rank}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderFilterSection = () => {
        return (
            <div className="">
                {/* Left Side */}
                <div className="h-36 sm:h-48 flex w-full items-center relative">
                    <span className="text-gnogen-gold font-roboto font-bold text-4xl xl:text-6xl absolute bottom-8">
                        {Localization.collections.filter}
                    </span>
                    <Divider className="absolute bottom-0" />
                </div>

                <div className="w-full flex relative py-8">
                    <Collapsible
                        tabIndex={0}
                        className="w-full"
                        openedClassName="w-full"
                        trigger={filterSectionTitle('ON SALE')}
                        triggerWhenOpen={filterSectionTitle('ON SALE', true)}>
                        <div className="w-full flex flex-col mx-8">
                            {marketplaceFilters.map((option) => {
                                return (
                                    <Checkbox
                                        key={option}
                                        className="py-2 flex items-center"
                                        checked={
                                            salesFilters.indexOf(option) >= 0
                                        }
                                        handleChange={(checked) => {
                                            let newSalesFilters = [
                                                ...salesFilters,
                                            ];
                                            if (!checked) {
                                                const index =
                                                    newSalesFilters.indexOf(
                                                        option
                                                    );
                                                if (index > -1) {
                                                    newSalesFilters.splice(
                                                        index,
                                                        1
                                                    ); // 2nd parameter means remove one item only
                                                }
                                            } else {
                                                newSalesFilters.push(option);
                                            }

                                            setSalesFilters(newSalesFilters);
                                        }}
                                        label={
                                            <span className="pl-2 font-roboto font-light">
                                                {option}
                                            </span>
                                        }
                                    />
                                );
                            })}
                        </div>
                    </Collapsible>
                    <Divider className="absolute bottom-0" />
                </div>

                {Object.entries(collection.filters).map(([key, options]) => {
                    return (
                        <div key={key} className="w-full flex relative py-8">
                            <Collapsible
                                tabIndex={0}
                                className="w-full"
                                openedClassName="w-full"
                                trigger={filterSectionTitle(key)}
                                triggerWhenOpen={filterSectionTitle(key, true)}>
                                <div className="w-full flex flex-col mx-8">
                                    {options.map((option) => {
                                        return (
                                            <Checkbox
                                                key={option}
                                                className="py-2 flex items-center"
                                                checked={
                                                    selectedFilters[
                                                        key
                                                    ]?.indexOf(option) >= 0
                                                }
                                                handleChange={(checked) => {
                                                    filterChange(
                                                        key,
                                                        option,
                                                        checked
                                                    );
                                                }}
                                                label={
                                                    <span className="pl-2 font-roboto font-light">
                                                        {option}
                                                    </span>
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </Collapsible>
                            <Divider className="absolute bottom-0" />
                        </div>
                    );
                })}
            </div>
        );
    };

    if (!collection) {
        return null;
    }

    return (
        <div className="w-full flex justify-center pt-navbarheight">
            <NftPreview
                open={previewingNft}
                nft={nftPreview}
                handleClose={() => {
                    setPreviewingNft(false);
                }}
            />
            <Drawer isOpen={openDrawer} setIsOpen={setOpenDrawer}>
                {renderFilterSection()}
            </Drawer>
            <div className="hidden lg:flex flex-col mx-4 w-96">
                {renderFilterSection()}
            </div>
            <div className="flex flex-col w-full max-w-8xl">
                {/* Right Side */}
                <div className="h-36 sm:h-48 flex w-full items-center relative ">
                    <div className="flex w-full items-center absolute bottom-8 px-8">
                        <FilterIcon
                            className="w-12 h-12 cursor-pointer block lg:hidden mr-4"
                            onClick={() => {
                                setOpenDrawer(true);
                            }}
                        />
                        {collection.logo ? (
                            <img
                                src={collection.logo}
                                className="h-12 sm:h-16 xl:h-24 rounded-lg"
                                alt={'GNOGONS'}
                            />
                        ) : (
                            <span className="text-gnogen-gold font-roboto font-bold text-4xl xl:text-6xl absolute bottom-8">
                                {collection.title}
                            </span>
                        )}
                        <div className="flex flex-col sm:flex-row text-center items-center pl-4 sm:pl-16">
                            {collection.seal && (
                                <img
                                    src={collection.seal}
                                    className="pb-2 sm:pb-0 sm:pr-4 h-12 xl:h-16"
                                    alt={'GNOGONS'}
                                />
                            )}
                            {collection.seal && (
                                <span className="font-roboto text-xs sm:text-sm xl:text-base">
                                    {Localization.collections.sealSignification}
                                </span>
                            )}
                        </div>
                    </div>
                    <Divider className="absolute bottom-0 px-8 max-w-8xl" />
                </div>
                <div className="flex p-8 items-center">
                    <span className="text-white font-roboto font-bold text-lg">
                        {Localization.collections.filter}
                    </span>
                    {salesFilters && salesFilters.length > 0 && (
                        <span className="text-gnogen-gray font-roboto font-bold px-2">
                            {'ON SALE: '}
                            {salesFilters.map((option, optionIndex) => {
                                return (
                                    <span
                                        key={optionIndex}
                                        className="relative px-4">
                                        {option}
                                        <XCircleIcon
                                            className="absolute w-4 h-4 top-[-12px] right-[-4px] hover:text-white cursor-pointer"
                                            onClick={() => {
                                                let newSalesFilters = [
                                                    ...salesFilters,
                                                ];
                                                const index =
                                                    newSalesFilters.indexOf(
                                                        option
                                                    );
                                                if (index > -1) {
                                                    newSalesFilters.splice(
                                                        index,
                                                        1
                                                    ); // 2nd parameter means remove one item only
                                                }
                                                setSalesFilters(
                                                    newSalesFilters
                                                );
                                            }}
                                        />
                                    </span>
                                );
                            })}
                        </span>
                    )}
                    {Object.entries(selectedFilters).map(([key, options]) => {
                        return (
                            <span
                                key={key}
                                className="text-gnogen-gray font-roboto font-bold px-2">
                                {key + ': '}
                                {options.map((option, optionIndex) => {
                                    return (
                                        <span
                                            key={optionIndex}
                                            className="relative px-4">
                                            {option}
                                            <XCircleIcon
                                                className="absolute w-4 h-4 top-[-12px] right-[-4px] hover:text-white cursor-pointer"
                                                onClick={() => {
                                                    filterChange(
                                                        key,
                                                        option,
                                                        false
                                                    );
                                                }}
                                            />
                                        </span>
                                    );
                                })}
                            </span>
                        );
                    })}
                </div>
                <InfiniteScroll
                    dataLength={displayedNfts.length} //This is important field to render the next data
                    next={fetchMore}
                    hasMore={displayedNfts.length < filteredAllNfts.length}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        displayedNfts.length === 0 ? (
                            <p style={{ textAlign: 'center' }}>
                                <b>
                                    No NFT found matching the selected filters.
                                </b>
                            </p>
                        ) : null
                    }>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 px-8">
                        {displayedNfts.map((nft, index) => {
                            return (
                                <NftCard key={index} nft={nft} index={index} />
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default CollectionNft;
