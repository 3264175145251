import React, { useEffect, useRef, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import EmidasLogoScene from '../../components/EmidasLogoScene';
import gnogenLogo from '../../assets/images/logo-transparent-white-1.png';
import comicsLogo from '../../assets/images/comics-logo.png';
import { collections } from '../../utils/config';
import Localization from '../../utils/localization';
import GnogenLoader from '../../components/GnogenLoader/';

const ComicCollection = (props) => {
    let { id } = useParams(); // Unpacking and retrieve id
    const navigate = useNavigate();
    const [collection, setCollection] = React.useState(null);
    const [hoveredItem, setHoveredItem] = React.useState({});
    const containerRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Parse through the collection items and retrieve all unique selectors
        collections.forEach((col, index) => {
            if (col.id === id) {
                setCollection(col);
                return;
            }
        });
    }, [setCollection, id]);

    const renderCollectionItem = (comicItem, index) => {
        let hovered = hoveredItem === comicItem;
        return (
            <div
                className="max-w-full relative overflow-hidden cursor-pointer mx-2 my-1 rounded-2xl"
                key={index}
                onMouseEnter={() => {
                    setHoveredItem(comicItem);
                }}
                onMouseLeave={() => {
                    setHoveredItem(null);
                }}
                onClick={() => {
                    //window.open(comicItem.url, '_blank');
                    navigate('/collections/' + id + '/' + (index + 1));
                }}>
                <div
                    className={`z-10 absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gnogen-transparent-gray animated-opacity ${
                        hovered ? 'opacity-100' : 'sm:opacity-0'
                    }`}>
                    <span
                        className={`pointer-events-none text-white text-2xl sm:text-4xl font-gnogen`}>
                        {comicItem.title}
                        <span className="hidden sm:inline">
                            {': ' + comicItem.subtitle}
                        </span>
                    </span>
                </div>
                <img
                    className={`animated-all w-full max-w-full ${
                        hovered ? 'scale-125' : 'scale-100'
                    }`}
                    src={comicItem.banner}
                    alt={comicItem.title}
                />
            </div>
        );
    };

    return (
        <div className="w-full flex flex-col items-center overflow-x-hidden">
            <div
                className="w-full flex flex-col items-center justify-center pt-navbarheight bg-black pb-1 relative"
                ref={containerRef}>
                <div className="flex justify-center py-12">
                    <img
                        className="w-32 mx-2 sm:w-64 sm:mx-4"
                        src={gnogenLogo}
                        alt={'GNOGEN'}
                    />
                    <img
                        className="w-32 mx-2 sm:w-64 sm:mx-4"
                        src={comicsLogo}
                        alt={'COMICS'}
                    />
                </div>
                <span className="text-white font-roboto text-xs">
                    {Localization.collections.presents}
                </span>
                <div className="h-48 sm:h-96"></div>
                <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                    <Suspense
                        fallback={<GnogenLoader className="w-48 h-48 pt-12" />}>
                        <Canvas className="w-48 h-48 pt-12 sm:pt-0">
                            <EmidasLogoScene orbitControl={false} />
                        </Canvas>
                    </Suspense>
                </div>
                {/*<div className="py-12 relative">
                    <animated.img
                        className=""
                        src={emidasCharging}
                        style={styles}
                    />
            </div>*/}
                <span className="text-white font-roboto py-12 text-xs">
                    {Localization.collections.browse}
                </span>
            </div>
            {collection?.comics.map((comicItem, index) => {
                return renderCollectionItem(comicItem, index);
            })}
        </div>
    );
};

export default ComicCollection;
