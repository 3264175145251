import React, { useState } from 'react';
import Slash from '../StylizedSlash';

export default function SelectableTitle(props) {
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className={`flex cursor-pointer relative ${
                props.containerClass ? props.containerClass : ''
            }`}
            onMouseEnter={() => {
                if (!props.disabled) {
                    setHovered(true);
                }
            }}
            onMouseLeave={() => {
                if (!props.disabled) {
                    setHovered(false);
                }
            }}
            onClick={props.onClick}>
            <Slash
                {...props}
                className="absolute left-0"
                hidden={!hovered && !props.selected}
                color={props.dashColor}
            />

            <span className={`${props.textClass ? props.textClass : ''}`}>
                {props.text}
            </span>

            <Slash
                {...props}
                className="absolute right-0"
                hidden={!hovered && !props.selected}
                color={props.dashColor}
            />
        </div>
    );
}
