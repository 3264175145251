import React, { useEffect } from 'react';
import './styles.scss';
import { animated, useSpring } from 'react-spring';

function Checkbox(props) {
    const [isChecked, setIsChecked] = React.useState(props.checked);
    //const [checkmarkLength, setCheckmarkLength] = useState(null);
    const checkboxAnimationStyle = useSpring({
        backgroundColor: isChecked ? '#ffb400' : 'transparent',
        borderColor: isChecked ? '#fff' : '#ddd',
    });

    useEffect(() => {
        if (props.checked !== isChecked) {
            setIsChecked(props.checked);
        }
    }, [props.checked, isChecked, setIsChecked]);

    const handleChange = () => {
        setIsChecked(!isChecked);
        if (props.handleChange) {
            props.handleChange(!isChecked);
        }
    };

    return (
        <label className={`custom-checkbox ${props.className}`}>
            <input
                type="checkbox"
                onChange={() => {
                    handleChange();
                }}
            />
            <animated.svg
                style={checkboxAnimationStyle}
                className={`checkbox ${isChecked ? 'checkbox--active' : ''}`}
                aria-hidden="true"
                viewBox="0 0 15 11"
                fill="none">
                <animated.path
                    d="M1 4.5L5 9L14 1"
                    strokeWidth="2"
                    stroke={isChecked ? '#fff' : 'none'}
                    //https://dev.to/tomdohnal/custom-checkbox-in-react-animated-and-accessible-3jk9
                    ref={(ref) => {
                        if (ref) {
                            //setCheckmarkLength(ref.getTotalLength());
                        }
                    }}
                />
            </animated.svg>
            {props.label}
        </label>
    );
}

export default Checkbox;
