import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Carrousel from '../../components/Carrousel';
import Localization from '../../utils/localization';
import SelectableTitle from '../../components/SelectableTitle';
import emidasAct6 from '../../assets/images/collections/emidas-act-6-512px-preview.png';
import emidasAct6Video from '../../assets/videos/emidas-act-6.mp4';
import aermesAct6 from '../../assets/images/collections/aermes-act-6-512px-preview.jpg';
import aermesAct6Video from '../../assets/videos/aermes-act-6.mp4';
import dogaAct1 from '../../assets/images/collections/doga-act-1-preview.jpg';
import dogaAct1Video from '../../assets/videos/doga-act-1.mp4';
import dragon from '../../assets/images/3-head-dragon.png';
import mediumIcon from '../../assets/icons/medium-gray.png';
import twitterIcon from '../../assets/icons/twitter-gray.png';
import discordIcon from '../../assets/icons/discord-gray.png';
import youtubeIcon from '../../assets/icons/youtube-gray.png';
import linktreeIcon from '../../assets/icons/linktree-gray.png';
import telegramIcon from '../../assets/icons/telegram-gray.png';
import goldenGalaxy from '../../assets/videos/golden-galaxy.mp4';

const comics = [
    {
        route: 'collections/emidas',
        title: 'EMIDAS',
        collection: 'GENESIS COLLECTION',
        image: emidasAct6,
        video: emidasAct6Video,
        state: 'SOLD OUT',
    },
    {
        title: 'AERMES',
        collection: 'GENESIS COLLECTION',
        image: aermesAct6,
        video: aermesAct6Video,
        state: 'SOLD OUT',
    },
    {
        title: 'DOGA',
        collection: 'GENESIS COLLECTION',
        image: dogaAct1,
        video: dogaAct1Video,
        state: 'JULY - AUGUST',
    },
    {
        title: 'ETHER',
        collection: 'GENESIS COLLECTION',
        state: 'ETA: TBA',
    },
    {
        title: '?',
        collection: 'GENESIS COLLECTION',
        state: 'ETA: TBA',
    },
];

const Home = () => {
    const navigate = useNavigate();
    const [carrouselPosition, setCarrouselPosition] = useState(0);
    const sectionsRefs = useRef([]);
    const videoRef = useRef(null);
    const tweetContainerRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTweets();
    }, []);

    const fetchTweets = async () => {
        let cacheBurstingHelper = new Date().getTime();
        let tweetsText = await fetch(
            '/tweets.txt?v=' + cacheBurstingHelper
        ).then((response) => response.text());
        tweetsText = tweetsText.replaceAll('\r', '');
        let tweetUrls = tweetsText.split('\n');
        tweetContainerRef.current.innerHTML = '';
        for (const tweetId of tweetUrls) {
            if (tweetId === '' || tweetId === '\r') continue;
            //https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function
            window.twttr.widgets.createTweet(
                tweetId + '',
                document.getElementById('tweet-container'),
                {
                    theme: 'dark',
                    conversation: 'none',
                    cards: 'hidden',
                    dnt: true,
                }
            );
        }
    };

    const Section = ({ children, className, sectionRef }) => {
        return (
            <div
                ref={sectionRef}
                className={`max-w-10xl px-8 flex flex-col w-full ${
                    className ? className : ''
                }`}>
                {children}
            </div>
        );
    };
    const FullSection = ({ children, className, sectionRef }) => {
        return (
            <div
                ref={sectionRef}
                className={`${
                    className ? className : ''
                } flex flex-col w-full`}>
                {children}
            </div>
        );
    };
    const InnerSection = ({ children, className }) => {
        return (
            <div
                className={`max-w-10xl px-8 flex w-full self-center ${
                    className ? className : ''
                }`}>
                {children}
            </div>
        );
    };

    const Title = ({ children, className }) => {
        return (
            <h2
                className={`ml-1 xs:ml-0 my-8 lg:my-24 text-6xl sm:text-8xl lg:text-9xl font-gnogen text-shadow ${
                    className ? className : ''
                }`}>
                {children}
            </h2>
        );
    };

    const renderComicItem = (comic, index) => {
        let marginClass = 'mx-4';
        if (index === 0) marginClass = 'mr-4';
        else if (index === comics.length - 1) marginClass = 'ml-4';
        return (
            <div
                className={`flex flex-col flex-none ${marginClass} relative z-10`}
                key={index}>
                {comic.image ? (
                    <img
                        className="h-48 sm:h-[32rem] object-contain h-fit self-center cursor-pointer"
                        src={comic.image}
                        alt={'Comic' + index}
                        onClick={() => {
                            navigate(comic.route);
                        }}
                    />
                ) : (
                    <div className="h-48 sm:h-[32rem] w-96 bg-gnogen-black flex justify-center items-center">
                        <span className="w-32 h-32 text-white font-roboto font-bold text-6xl h-fit p-8 text-center">
                            ?
                        </span>
                    </div>
                )}
                {comic.video ? (
                    <video
                        className={`opacity-0 hover:opacity-100 absolute h-48 sm:h-[32rem] object-contain h-fit animated-opacity cursor-pointer`}
                        autobuffer="autobuffer"
                        preload="preload"
                        autoPlay
                        loop
                        muted
                        playsInline
                        onClick={() => {
                            navigate(comic.route);
                        }}>
                        <source type="video/mp4" src={comic.video} />
                    </video>
                ) : null}

                <span className="pt-8 pb-4 text-center font-roboto text-white text-shadow-light">
                    {comic.title}
                </span>
                <span className="py-4 text-center font-roboto font-bold text-white text-shadow-light">
                    {comic.collection}
                </span>
                <span className="py-4 text-center font-roboto font-bold text-red-500 text-shadow-light">
                    {comic.state}
                </span>
            </div>
        );
    };

    return (
        <div className="w-full flex flex-col items-center">
            <FullSection className="h-screen text-shadow pb-36 px-8 bg-[url('/src/assets/images/home-bg.jpg')] bg-top bg-cover flex flex-col justify-center min-h-[90vh]">
                <InnerSection className="flex-col items-center">
                    {/* <div className="hidden sm:block max-w-xs lg:max-w-2xl">
                        <GnogenLogo
                            className={`${'fill-gnogen-gold'} stroke-1 w-full ml-4 mr-6`}
                        />
    </div>*/}
                    <span className="text-5xl text-white sm:p-12 lg:p-24 font-gnogen text-center">
                        {Localization.home.section1Subtitle1}&nbsp;
                        <span className="text-gnogen-gold">
                            {Localization.home.power}
                        </span>
                        &nbsp;
                        {Localization.home.section1Subtitle2}
                    </span>
                </InnerSection>
            </FullSection>

            <FullSection
                className="py-12 bg-black"
                sectionRef={(el) => (sectionsRefs.current[1] = el)}>
                <InnerSection>
                    <Title className="text-white">
                        {Localization.home.comics}
                    </Title>
                </InnerSection>

                <InnerSection>
                    <Carrousel
                        max-width={1792}
                        className="h-fit lg:my-12"
                        buttonsClassName="h-48 sm:h-[32rem] top-0"
                        scrollWidth={383}
                        startPosition={carrouselPosition}
                        onPositionChange={(newPos) => {
                            setCarrouselPosition(newPos);
                        }}>
                        {comics.map(renderComicItem)}
                    </Carrousel>
                </InnerSection>
            </FullSection>

            <Section
                className="py-12 relative z-10 bg-[url('/src/assets/images/gnogen-official-icon.svg')] bg-[center_bottom_26rem] sm:bg-center bg-no-repeat bg-[length:90px_90px] sm:bg-[length:160px_160px]"
                sectionRef={(el) => (sectionsRefs.current[4] = el)}>
                <Title className="max-w-4xl">
                    {Localization.home.cardGame}
                </Title>
                <span className="text-center font-gnogen text-6xl sm:text-9xl mt-28 mb-4 text-shadow-white">
                    {Localization.home.season + ' 1'}
                </span>
                <span className="text-center font-lato text-4xl">
                    {Localization.home.inDev}
                </span>
                <span className="text-center font-lato text-4xl">
                    {Localization.home.eta + ' Q4 2022'}
                </span>
                <div className="hidden sm:flex justify-between mt-28 mb-12 px-4">
                    {[
                        Localization.home.buy,
                        Localization.home.collect,
                        Localization.home.trade,
                        Localization.home.battle,
                        Localization.home.sell,
                        Localization.home.wager,
                        Localization.home.win,
                    ].map((element, index) => {
                        return (
                            <span
                                className="font-roboto text-xl sm:text-2xl font-light"
                                key={index}>
                                {element}
                            </span>
                        );
                    })}
                </div>
                <div className="flex flex-col block sm:hidden mt-32">
                    <div className="flex justify-around">
                        {[
                            Localization.home.buy,
                            Localization.home.collect,
                            Localization.home.trade,
                            Localization.home.battle,
                        ].map((element, index) => {
                            return (
                                <span
                                    className="font-roboto text-xl sm:text-2xl font-light"
                                    key={index}>
                                    {element}
                                </span>
                            );
                        })}
                    </div>
                    <div className="flex justify-around">
                        {[
                            Localization.home.sell,
                            Localization.home.wager,
                            Localization.home.win,
                        ].map((element, index) => {
                            return (
                                <span
                                    className="font-roboto text-xl sm:text-2xl font-light"
                                    key={index}>
                                    {element}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </Section>

            <FullSection
                className="py-12 bg-gnogen-carrara relative"
                sectionRef={(el) => (sectionsRefs.current[4] = el)}>
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    loop
                    className="absolute left-0 top-0 w-full h-full z-10 object-cover">
                    <source src={goldenGalaxy} type="video/mp4" />
                </video>
                <InnerSection className="flex-col z-20">
                    <div className="my-12 text-2xl leading-8 sm:text-[4rem] sm:leading-[4rem] font-lato font-bold text-white text-shadow flex items-center">
                        <span className="flex flex-col pr-4">
                            <span>
                                {Localization.home.digital +
                                    ' // ' +
                                    Localization.home.the}
                                &nbsp;
                                <span className="text-gnogen-gold">
                                    {Localization.home.gnogenverse}
                                </span>
                            </span>
                            <span>{Localization.home.realEstate}</span>
                        </span>
                    </div>
                    <span className="text-center font-gnogen text-6xl sm:text-9xl mt-24 text-shadow-white">
                        {Localization.home.claimable}
                    </span>
                    <span className="text-center font-gnogen text-6xl sm:text-9xl mb-8 text-shadow-white">
                        {' Q4 2022'}
                    </span>

                    <div className="hidden sm:flex justify-between mt-32 mb-16 px-4 text-white text-shadow">
                        {[
                            '10K ' + Localization.home.lands,
                            Localization.home.build,
                            Localization.home.rent,
                            Localization.home.sell,
                            Localization.home.host,
                            Localization.home.vaults,
                            Localization.home.win,
                        ].map((element, index) => {
                            return (
                                <span
                                    className="font-roboto text-xl sm:text-2xl font-light"
                                    key={index}>
                                    {element}
                                </span>
                            );
                        })}
                    </div>
                    <div className="flex flex-col block sm:hidden mt-32 text-white text-shadow">
                        <div className="flex justify-around">
                            {[
                                '10K ' + Localization.home.lands,
                                Localization.home.build,
                                Localization.home.rent,
                            ].map((element, index) => {
                                return (
                                    <span
                                        className="font-roboto text-xl sm:text-2xl font-light"
                                        key={index}>
                                        {element}
                                    </span>
                                );
                            })}
                        </div>
                        <div className="flex justify-around">
                            {[
                                Localization.home.host,
                                Localization.home.vaults,
                                Localization.home.win,
                            ].map((element, index) => {
                                return (
                                    <span
                                        className="font-roboto text-xl sm:text-2xl font-light"
                                        key={index}>
                                        {element}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </InnerSection>
            </FullSection>

            <FullSection
                className="relative bg-[url('/src/assets/images/mining-bg.jpg')] bg-bottom bg-cover items-center pt-24 pb-4 min-h-8xl"
                sectionRef={(el) => (sectionsRefs.current[2] = el)}>
                <img
                    className="absolute top-0 w-full max-w-8xl mt-[-4rem] z-30"
                    src={dragon}
                    alt="Dragon"
                />
                <InnerSection>
                    <Title className="text-white z-30">
                        {Localization.home.gng}
                    </Title>
                </InnerSection>
                {/*<InnerSection className="relative sm:pb-12 pt-24">*/}
                <div className="flex w-full justify-around lg:pl-8">
                    <div className="flex flex-col w-fit h-fit pl-4 pr-32 py-4 bg-white/[.5] rounded-2xl sm:text-xl text-shadow-light ml-1 sm:ml-0 font-roboto z-30">
                        <span className="text-white pb-2 font-bold text-2xl sm:text-4xl">
                            {Localization.home.theGreatNft}&nbsp;
                            <span className="text-gnogen-gold">
                                {Localization.home.war}
                            </span>
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;
                            {Localization.home.gngText1}
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold font-bold">
                                /&nbsp;{Localization.home.power}
                            </span>
                            &nbsp;{Localization.home.gngText2}
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;
                            {Localization.home.gngText3}
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;
                            {Localization.home.gngText4Start}
                            &nbsp;
                            <span className="font-bold">
                                {Localization.home.power}
                            </span>
                            &nbsp;
                            {Localization.home.gngText4End}
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;
                            {Localization.home.gngText5}
                        </span>
                        <span className="py-2">
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;
                            {Localization.home.gngText6}
                        </span>
                    </div>
                    <div
                        className="px-2 h-fit"
                        onClick={() => {
                            //window.open(element.url, '_blank');
                        }}>
                        <span className="text-vertical font-gnogen font-black text-6xl lg:text-[9rem] leading-none text-black ml-auto text-shadow">
                            {Localization.home.eta}&nbsp;
                            <span className="text-gnogen-gold">/</span>
                            &nbsp;Q3&nbsp;2022
                        </span>
                    </div>
                </div>
                {/*</InnerSection>*/}
            </FullSection>

            <Section
                id="tweets-container"
                className="py-12"
                sectionRef={(el) => (sectionsRefs.current[4] = el)}>
                <Title>{Localization.home.updates}</Title>
                <h2 className="link">{Localization.home.status}</h2>
                <div
                    id="tweet-container"
                    className="flex overflow-auto"
                    ref={tweetContainerRef}></div>
            </Section>

            <FullSection
                className="bg-black text-white py-24"
                sectionRef={(el) => (sectionsRefs.current[5] = el)}>
                <InnerSection className="flex-col lg:flex-col justify-between">
                    <div
                        className={
                            "flex flex-col w-full h-96 bg-[url('./assets/images/emidas-charging.png')] bg-contain bg-right bg-no-repeat flex-grow mt-4"
                        }>
                        <Title className="text-white z-10 !mt-2 !mb-8">
                            {Localization.home.community}
                        </Title>

                        <iframe
                            src="https://egld.community/api/products/e11ad818-d54a-4f8d-9644-5365786d4ba9/upvotes/embed"
                            width="290"
                            height="70"
                            style={{
                                borderRadius: '8px',
                                border: 'none',
                                width: '290px',
                                height: '70px',
                            }}></iframe>
                    </div>
                    <div className="">
                        <div className="flex flex-col sm:flex-row items-center justify-around max-h-full pt-12 pb-8 pr-4 self-center w-full lg:max-w-6xl">
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={mediumIcon}
                                            alt="MEDIUM"
                                        />
                                        MEDIUM
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://gnogen.medium.com',
                                        '_blank'
                                    );
                                }}
                            />
                            <br />
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={twitterIcon}
                                            alt="TWITTER"
                                        />
                                        TWITTER
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://twitter.com/gnogen',
                                        '_blank'
                                    );
                                }}
                            />
                            <br />
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={discordIcon}
                                            alt="DISCORD"
                                        />
                                        DISCORD
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://discord.gg/gnogen',
                                        '_blank'
                                    );
                                }}
                            />
                            <br />
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={youtubeIcon}
                                            alt="YOUTUBE"
                                        />
                                        YOUTUBE
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://www.youtube.com/channel/UC_E8NX1kjtpyEYPP0AsCoOA',
                                        '_blank'
                                    );
                                }}
                            />
                            <br />
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={linktreeIcon}
                                            alt="LINKTREE"
                                        />
                                        LINKTREE
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://linktr.ee/gnogen',
                                        '_blank'
                                    );
                                }}
                            />
                            <br />
                            <SelectableTitle
                                text={
                                    <div className="flex items-center">
                                        <img
                                            className="h-4 pb-1 pr-4"
                                            src={telegramIcon}
                                            alt="TELEGRAM"
                                        />
                                        TELEGRAM
                                    </div>
                                }
                                textClass="font-roboto font-light animated-text text-shadow-light px-4 hover:glow"
                                width={0.4}
                                height={1.25}
                                onClick={() => {
                                    window.open(
                                        'https://t.me/gnogen',
                                        '_blank'
                                    );
                                }}
                            />
                        </div>
                    </div>
                </InnerSection>
            </FullSection>
        </div>
    );
};

export default Home;
