import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '../../components/Divider';
import Localization from '../../utils/localization';
import { collections } from '../../utils/config';

const Collections = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const Title = ({ children, className, expand }) => {
        return (
            <h2
                className={`text-5xl md:text-7xl w-full py-2 font-gnogen flex justify-between items-center text-shadow ${
                    className ? className : ''
                }`}>
                {children}
            </h2>
        );
    };

    const renderCollectionSection = (collection) => {
        return (
            <div
                className={`w-full flex flex-col content-center items-center my-8 px-4 ${
                    collection.path ? 'cursor-pointer' : ''
                }`}
                key={collection.title}>
                <Title expand>
                    <div className="flex flex-col w-full pt-16">
                        {collection.title}
                    </div>
                </Title>
                <div
                    className="w-full h-full relative group"
                    onClick={() => {
                        if (!collection.comingSoon)
                            navigate('/collections/' + collection.id);
                    }}>
                    <div className="flex flex-col md:absolute">
                        <span className="text-gnogen-gold text-3xl md:text-4xl font-halcom font-bold py-2">
                            {collection.subtitle}
                        </span>
                        <span className="text-gnogen-black font-roboto font-light text-2xl py-2">
                            {collection.state}
                        </span>
                    </div>
                    <img
                        className="mx-auto max-h-64 md:max-h-96 object-contain animated-transform-fast hover:scale-[1.05]"
                        src={collection.emblem}
                        alt={collection.title}
                    />
                </div>
                <div
                    className="w-full h-full pt-16 flex flex-col sm:flex-row flex-[6] pl-4 text-center pb-16"
                    onClick={() => {
                        if (!collection.comingSoon)
                            navigate('/collections/' + collection.id);
                    }}>
                    <div className="flex flex-col flex-[1]">
                        <span className="text-4xl font-halcom font-black py-2">
                            {collection.collection}
                        </span>
                        <span className="text-2xl font-halcom font py-2">
                            {Localization.collections.collection}
                        </span>
                    </div>
                    <div className="flex flex-col flex-[1]">
                        <span className="text-4xl font-halcom font-black py-2">
                            {collection.mintage}
                        </span>
                        <span className="text-2xl font-halcom font py-2">
                            {Localization.collections.mintage}
                        </span>
                    </div>
                    <div className="flex flex-col flex-[1]">
                        <span className="text-4xl font-halcom font-black py-2">
                            {collection.year}
                        </span>
                        <span className="text-2xl font-halcom font py-2">
                            {Localization.collections.year}
                        </span>
                    </div>
                </div>
                <Divider dividerClassName="border-gnogen-black" />
            </div>
        );
    };

    return (
        <div className="w-full h-full mt-navbarheight pt-12 pb-36 flex flex-col items-center max-w-7xl">
            {collections.map((collection) => {
                return renderCollectionSection(collection);
            })}
        </div>
    );
};

export default Collections;
