// ES6 module syntax
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
        header: {
            gnogen: 'GNOGEN',
            gnogonsRevamped: 'GNOGONS REVAMPED',
            elrond: 'MULTIVERSX',
            greatWar: 'THE GREAT WAR',
            collections: 'COLLECTIONS',
            analytics: 'ANALYTICS',
            shop: 'SHOP',
            gnogencon: 'GNOGENCON',
            roadmap: 'ROADMAP',
            wiki: 'WIKI',
            about: 'ABOUT',
        },
        homeOld: {
            synopsis: 'Synopsis',
            team: 'Gnogen Studios',
            upcoming: 'Upcoming',
            updates: 'Updates',
            status: 'Status team building and laying the foundation',
            community: 'Community',
            synopsisText: [
                'Gnosis Generation creating the #1 Metaverse Experience. Built on Elrond.',
            ],
        },
        home: {
            section1Subtitle1: 'IMMUTABLE STORYTELLING BUILT IN WEB 3.',
            power: 'POWER',
            section1Subtitle2: 'TO THE PEOPLE',
            comics: 'COMICS',
            upcoming: 'Upcoming',
            updates: 'Updates',
            community: 'Community',
            cardGame: 'Digital Trading Card Game',
            season: 'Season',
            inDev: 'IN DEVELOPMENT',
            eta: 'ETA',
            buy: 'BUY.',
            collect: 'COLLECT.',
            trade: 'TRADE.',
            battle: 'BATTLE.',
            sell: 'SELL.',
            wager: 'WAGER.',
            win: 'WIN.',
            digital: 'DIGITAL',
            the: 'THE',
            gnogenverse: 'GNOGENVERSE',
            realEstate: 'REAL ESTATE',
            claimable: 'CLAIMABLE',
            lands: 'LANDS.',
            build: 'BUILD.',
            rent: 'RENT.',
            host: 'HOST.',
            vaults: 'VAULTS.',
            gng: 'GNG MINING',
            theGreatNft: 'THE GREAT NFT',
            war: 'WAR',
            power: 'Power',
            gngText1:
                'Every day, you can enter your Gnogen Authentic NFT into The Great War.',
            gngText2: 'is King in The Great War.',
            gngText3: "Each of your NFTs will face off against another's.",
            gngText4Start: 'Higher Power wins. Equal',
            gngText4End: 'ends in a draw. Winners share the spoils.',
            gngText5: 'Each day begins anew. Prepare your miners.',
            gngText6: 'The Gnogen Metaverse Utility Token is coming.',
        },
        elrond: {
            elrondTextsv2: [
                'THE NEXT BITCOIN',
                'BLAZING FAST -',
                'ULTRA SECURE -',
                'USER FRIENDLY -',
                'ALWAYS ON -',
                'NEXT GENERATION -',
                'SMART CONTRACT -',
                'BLOCKCHAIN . ',
            ],
            elrondBottomElements: [
                [
                    'Secure Proof of Stake',
                    '3,200 nodes are live all over the world',
                ],
                [
                    'Immutable Public Ledger',
                    'Permanent. Accurate. Secure. IMMUTABLE.',
                ],
                [
                    'World Class Developer Team',
                    'PhDs. VCs. Engineers. Visionaries. Hard Core. Focused.',
                ],
                [
                    'Layer 1 Blockchain Monster',
                    '15,000 transactions per second. Lambo Speeds.',
                ],
                [
                    'Carbon Negative Energy Footprint',
                    'The first carbon negative blockchain in the world.',
                ],
            ],
            maiarWallet: 'MAIAR WALLET',
            maiarExchange: 'MAIAR EXCHANGE',
            whitepaper: 'WHITEPAPER',
            elrondHome: 'ELROND HOME',
            twitter: 'TWITTER',
            elrondNetwork: 'The Elrond Network Blockchain',
            elrondTexts: [
                'THE ELROND NETWORK BLOCKCHAIN UTILIZES PROOF OF STAKE TECHNOLOGY WITHIN A PUBLIC LEDGER THAT RECORDS ANY AND ALL TRANSACTIONS DONE BY USERS OF THE BLOCKCHAIN, WITHOUT BIAS OR AMBIGUITY',
                'ALL OF THESE TRANSACTIONS ARE FOREVER ESTABLISHED ON THE BLOCKCHAIN PERMANENT AND IMMUTABLE FOR ALL TIME',
                'A PUBLIC LEDGER SUCH AS THE ONE CRAFTED BY THE ELROND TEAM IS AN ENGINEERING ACHIEVEMENT.',
                'SECURE. FAST. RELIABLE.',
                'BASICALLY THE PERFECT BLOCKCHAIN TO BUILD THE BEST METAVERSE ON EARTH',
                '15,000 TPS',
                '3,200 NODES SECURING THE NETWORK',
                'THE ENTIRE NETWORK IS HAS A CARBON-NEGATIVE FOOTPRINT.',
                'APIS. DEVELOPER TOOLS. DEX. STAKING REWARDS.',
                'THE BLOCKCHAIN’S NATIVE TOKEN IS EGLD. ',
                'WITH EGLD YOU CAN SWAP IT FOR OTHER CRYPTO TOKENS, BUY NFTS, MAKE PAYMENTS WITH THOUSANDS OF RETAILERS AROUND THE WORLD, AND EVEN STAKE IT FOR 14% APY.',
            ],
            elrond: 'elrond',
            download: 'Download the Maiar Phone App',
            nextGen: 'Next Gen Cyrpto Wallet',
            downloadTexts: [
                'DOWNLOAD THE MAIAR PHONE APP. IT IS A NON-CUSTODIAL CRYPTO CURRENCY WALLET. YOU CAN USE IT TO STORE CRYPTO ASSETS OR EVEN CONNECT TO ONLINE APPLICATIONS WHERE YOU CAN TAKE PART IN THE WEB3 WORLD OF TOMORROW. ',
                'WHEN SETTING UP YOUR NEW MAIAR WALLET, MAKE SURE TO WRITE DOWN YOUR 24 WORD SECRET PHRASE AND PLACE IT SOMEWHERE SAFE AND SECURE.',
                'GNOGEN NOR ELROND WILL NEVER ASK YOU FOR YOUR SECRET PHRASE. ANYONE ASKING YOU FOR YOUR SECRET PHRASE IS LIKELY ATTEMPTING TO SCAM YOU. EXERCISE EXTREME CAUTION WHEN INPUTTING YOUR SECRET PHRASE ON ANY DEVICE, OR CONNECTING TO ANY ONLINE APPLICATION YOU ARE UNFAMILIAR WITH.',
                'IT MAY BE WISE TO OWN MULTIPLE WALLETS TO PREVENT A SINGLE POINT OF FAILURE, SHOULD ANY OF YOUR WALLETS BECOME COMPROMISED. NEVER CONNECT TO AN UMFAMILIAR WEBSITE USING YOUR MAIN WALLET. ',
                'YOU CAN CREATE NEW WALLETS AT WALLET.ELROND.COM',
                'ONCE YOU HAVE DOWNLOADED THE APP AND SAVED YOUR SECRET PHRASE, YOU ARE READY TO PARTICIPATE IN THE ELROND ECOSYSTEM. WELCOME AND ENJOY!',
            ],
            research: ' Do your own research',
        },
        collections: {
            acts: 'ACTS',
            soldout: 'SOLD OUT',
            genesis: 'GENESIS',
            collection: 'COLLECTION',
            mintage: 'MINTAGE',
            year: 'YEAR',
            presents: 'PRESENTS',
            browse: 'BROWSE THE COLLECTION',
            filter: 'FILTER',
            power: 'POWER',
            rank: 'RANK',
            sealSignification:
                'SIGNIFIES THIS GNOGON HAS NOT BEEN USED IN FUSION',
            comics: {
                emidas: [
                    {
                        title: 'EMIDAS Act 1: Zero to One',
                        texts: [
                            `Emidas' story begins as a young lad, in a remote village somewhere in the beautiful scenic rural hills of Romania.`,
                            `Raised as an orphan, Emidas was prone to bullying, and rumors about what happened to his parents. The bullying was incessant, largely focusing on his small stature, and penchant for books. But even as a grade school orphan, Emidas showed promise. The villagers quickly began to take notice of his intellect. New rumors spread about his ability to solve nearly any problem, no matter the complexity.`,
                            `The village elders, faced with increasing pressures facing their population over fears of famine, war, and disease, turned to Emidas. The boy was just 9 years old. An elite group of the village's finest warriors adopted Emidas. Every day the boy would train in combat. And every night, he was tasked with finding solutions to all manner of problems.`,
                            `From a scrawny, bullied orphan, Emidas grew. The years of training turned him into the village's finest warrior. Master of every weapon, master of hand to hand combat, and the hope of this small village. As the full moon on the month of his birthday approached, the 12 surrounding tribes decided it was too dangerous to let the boy continue to live and become an even greater threat.`,
                            `A plan was formed, for all 12 villages, to attack the village of Elrond. Fearing Emidas' problem solving abilities could be linked to a genetic mutation found in the village, the plan was to annihilate every man, woman, and child.`,
                            `Days before the night of the attack, a strange letter appeared on Emidas' nightstand. A cryptic puzzle, along with a map into the most dangerous regions of the nearby mountainside. Under the threat of the approaching attack, Emidas ignored the letter. With the village elders chaotically pleading with Emidas to save people, day and night he did not sleep. He must find a solution he thought. He must.`,
                            `With just a few days left until the imminent attack, Emidas returned to his room one day to find the letter, out and placed neatly on his bed. He spent all night deciphering the letter, and decoded a hidden key that allowed him to interpret the map. With no hope left, and all options exhausted, Emidas took his most trusted allies, and journeyed into the mountains.`,
                            `With the group running at full speed, resting for nothing, they reach a clearing where they find a mysterious rock, that does not match anything else found in the region. Seeing a glimmering sword stuck halfway within the stone, Emidas turned to his comrades to exclaim, "this sword! my comrades perhaps this is the answer to our troubles. Perhaps this will be the force that protects our people"!`,
                            `But strangely, the rest replied, "Emidas, perhaps you are weary from all the sleepless nights you have been experiencing. There is nothing here but a strange stone".`,
                            `Puzzled and confused, he walks back towards the stone. Placing both hands around the handle, he feels a cool breeze. The full moon begins to shine brighter. The hairs on the back of his neck begin to rise. He must save the villagers at all costs. He must. `,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 2: Fear. Uncertainty. Doubt.',
                        texts: [
                            `“Emidas, we don’t have time for this! Why have you brought us to this strange place?! We have always placed our trust in you, but our people will be invaded at a moment’s notice! Tell us why we have come here!”`,
                            `Mesmerized by the glowing aura that had enveloped him, Emidas could hear nothing but pitch silence.`,
                            `“EMIDAS! Our families, our homes…all our village cries out to us, even from here!”`,
                            `“We cannot tarry here any longer! We must go back!”`,
                            `Knowing this is what the mysterious letter had brought him to, Emidas grips the handle with all his might and begins to drag the sword from the stone.`,
                            `Bit by bit, the sword appears to listen! His eyes grow wider as the beast begins to glow. As if it were waiting for its master, the sword slides out and activates.`,
                            `Seven shards of bright light surround Emidas. A shield appears suddenly, fixated onto his left arm. A blinding light emits from Emidas’ entire body — the men around stutter back and shield their eyes in terror.`,
                            `When the light dissipates, things slowly come into focus. Standing before them, they see Emidas glowing, electricity coursing throughout his body.`,
                            `Emidas turns to them with a gaze they had never seen before. “With this power, we will save our people and deliver them from evil. Let us make haste. The battle of our lives awaits.”`,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 3: DeFi the enemy. Enter the Flywheel.',
                        texts: [
                            `Returning to their village, the men can already see the encroaching army. The sheer scale and size of the approaching force leave them unable to muster up much courage.`,
                            `“Emidas, do you have a plan? What are we to do…” asks Lucian in a hushed tone.`,
                            `“Someone left that letter on my bed on purpose. Someone is trying to help us win this fight,” he responded.`,
                            `“Ok, I understand now that there was a method to your madness, but what can 1 sword and 1 shield do to defend us against so many?” he shot back.`,
                            `Unable to answer, Emidas, perplexed, sits down.`,
                            `After closing his eyes for a few moments, he exhales a heavy sigh of burden. “Lucian, command the Elrond Warriors to the North and East gates and wait for my signal. I will ride through the forest to the eastern cliffs, and will draw the enemy away.”`,
                            `He continued with a somber voice, “it’s me they want most. I will pull their forces away from the gates. When the 7 swords of light will appear in the sky, lead our men out through the gates, and counter-attack their split forces.”`,
                            `Dawn approaches, and so does the enemy army towards the front gates. As the arrows fly, and the castle walls take their beating, Lucian sees the swords near the forest edge, and can faintly hear Emidas shout, “LONG. LIVE. ELROND!”`,
                            `The droves who were once marching forward toward the gate break formation. Blinded by the hubris of their seemingly colossal advantage, the armies of the twelve, who had previously never fought side by side before, scattered like roaches who had just seen the light; each man wanting to be the one to proudly exclaim that he was the one who slew the Great Emidas.`,
                            `Into the Eastern Forest, they went. With the Elrond Warriors pressing from the rear, the enemy forces catch up to Emidas. They were not prepared for the power they would encounter. When they proceed past the forest edge, they see Emidas by the Eastern cliffs. With electricity coursing all over his body, he lifts The Beast high into the air. Thunder and lightning crashing all around, Emidas unleashes the fury of all the pain he had ever endured throughout his life.`,
                            `For the village that had once outcast him as a pathetic orphan, Emidas’ heart was filled with courage. For the men who had adopted him and had become his brothers, he knew this was the defining moment of all their lives. Wave after wave he crushes the blitz of enemy forces.`,
                            `With every passing second, Emidas becomes more familiar with the powers the Beast had bestowed upon him. No arrow could reach his skin, for the 7 light swords would constantly encircle him, protecting him even without his command. The shield on his left arm ejects outward, spinning wildly around the battlefield, slicing through the enemy. As for the Beast itself, the eyes of all 3 dragon heads glow bright red. They suck the thunder from the air and cause them to come crashing down upon the enemy.`,
                            `Fortune appears to be on Emidas’ side, when suddenly, a strange woman, shining in a blazing inferno of blue fire, rips through the entire forest and throws Emidas over the edge of the cliff…`,
                            `Dazed and confused, we see Emidas, eyes wide open, plunging towards the depths below. Under the crushing realization he has failed, his life flashes before his eyes.`,
                            `Just as he has had time to process the inevitability of the battle being lost, and Elrond likely to be wiped from the face of the Earth, The Beast emits a noise — electricity chirping loudly in Emidas’ ear.`,
                            `Expecting an impact that never comes, Emidas is suddenly transported through a portal, which spits him out into the airspace above the enemy forces! As the men below begin to breathe a sigh of relief, they turn their gaze up towards the sun and see a glowing God descending towards them. To their horror and disbelief, it is Emidas.`,
                            `Our hero, bolstered by this new ability the sword had activated, decimates the ones below, before setting his gaze upon the Woman in blue.`,
                            `The two engage in combat, as the men around clear out a space. As much as Emidas had trained, he was no match for this foe. Jab. Elbow. Spinning Kick. Uppercut. Our hero is outclassed and outmatched. The woman’s attacks are too fast for the light swords to react in time. Her agility, too great for any of Emidas’ attacks to land a hit, leaves her untouched.`,
                            `Breathing heavily and unsure of what to do next, Emidas turns to see another portal has opened to his right. The Beast’s eyes begin to glow once more, and the light swords shove Emidas through. Leaving the battlefield behind, Emidas enters the Flywheel. Where is our hero heading to at a time like this…`,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 4: The Blockchain Trilemma',
                        texts: [
                            `Arriving at his destination, his eyes take time to adjust. Emidas finds himself in a dark cave. He can smell the musty thick air — hot like the center of a volcano. He searches for hints as to why the Beast has led him here.`,
                            `As soon as Emidas commands the light swords to illuminate the environment, six enormous red eyes light up the cave instead. The sword's eyes glow red as well, as Emidas lifts his head to see a creature of immense proportions. Standing before our hero is a bright golden, three-headed dragon the size of which was unimaginable.`,
                            `THE BLOCKCHAIN TRILEMMA: “EMIDAS! We have been waiting for you for eons…and so you have finally emerged.”`,
                            `EMIDAS (taking an instinctual step back, responds with a shaky voice): “How do you know my name…?”`,
                            `BLOCKCHAIN TRILEMMA: “The prophecy has foretold of your arrival since the dawn of time. The Architect commanded us to wait behind in this wretched realm so that we would help guide the chosen one who would bring balance to this broken world.”`,
                            `EMIDAS: “Pr…prophecy? I am sorry, but I don’t have time for this. MY PEOPLE ARE UNDER ATTACK! ARE YOU FRIEND OR FOE?! TELL ME NOW!”`,
                            `BLOCKCHAIN TRILEMMA: “THAT remains to be seen young Chosen One. We have been watching you since you entered this realm 18 years ago. You have survived and grown through countless tribulations. You have endured the hatred, scorn, envy, and enmity of your own people. You have grown to become the man you are now, while deprived of the two guardians that brought you into existence.”`,
                            `A lump starts to form in Emidas’ throat. Emotions begin to swell within his heart.`,
                            `EMIDAS: “Please…these people can be redeemed. They are lost, but I know I can lead them to better days. Please, with haste, tell me what it is you want from me. I do not know what else to do — they will surely be annihilated without my presence. I must return.”`,
                            `BLOCKCHAIN TRILEMMA: “You cannot defeat the Woman in Blue Flames in your current state. We can help you unlock the full power of the Beast you wield — under one condition.”`,
                            `EMIDAS (EYES WIDENING): “Yes! I will do whatever is asked! What must I do to receive this power?”`,
                            `BLOCKCHAIN TRILEMMA: “There is a sacred stone hidden within these canyon walls. Find it, and we will release the limits we placed on the Beast.”`,
                            `As soon as these words are uttered, the three-headed dragon attacks, clawing a deep wound into Emidas’ shoulder.`,
                            `Leaping back, Emidas portals out of the cave to the air above. Scanning for any hint of where the stone could be, Emidas sees the Dragon burst through the cave ceiling, into the airspace below.`,
                            `He tries to use the portal ability to search frantically, within every nook and cranny of the canyon. How is he to find 1 stone in a canyon full of stones? …`,
                            `Meanwhile, the Blockchain Trilemma appears to have a singular focus. Every portal Emidas enters to try and avoid the Dragon, it still appears on the other side, waiting to ambush him. Clawing and breathing fire at every turn, the Dragon makes it near impossible for Emidas to have clarity of thought. He finds it difficult to form any sort of strategy.`,
                            `To buy himself some time, he scatters his light swords in 7 different directions. Each one he activates in perfect timing, with the first one blinding one of the Dragon’s heads. As the Dragon turns to avoid the blinding light, Emidas has already prepared another light sword to shine with all its might to blind it again and again.`,
                            `In the midst of being blinded, the center Dragon head lets loose the hint of a smirk. Emidas wonders what this being is truly thinking, but does not have time to dwell on it. Struggling with blinding light all around it, the Beast flops and flails his enormously spiked tail, causing huge chunks of granite to fly all around Emidas.`,
                            `The Blockchain Trilemma hits more stone high into the air and flies above it. With all three heads charging up their fire breath once again, it sprays red hot granite chunks all over the arena.`,
                            `Desperation fills Emidas’ every muscle, and his eyes begin to glow white. His portal ability is stretched to its current limits, and he teleports fifteen thousand times in under a second — masterfully avoiding every piece of stone hurled at him.`,
                            `When the torrent of shrapnel subsides, the Dragon and Emidas stand on opposing cliff edges - both breathing heavily. With all three heads taking in a deep breath of air once again, the Dragon converges its three fire breaths into one beam and aims it straight at Emidas.`,
                            `Twisting his feet and legs into the ground beneath him and bracing for impact, Emidas closes his eyes and prepares his shield to absorb the attack. His warrior suit burning up around him, Emidas wonders if this is the end. Was he not the chosen one? Why bring him here only to be obliterated?`,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 5: Power Overwhelming',
                        texts: [
                            `As the Blockchain Trilemma launches its final attack towards Emidas, our hero is struck by an epiphany!`,
                            `The stone was never in the walls of the canyon…It was on top of the Dragon’s forehead all along!`,
                            `Moments before the Dragon’s attack makes contact with Emidas’ shield, he portals, barely escaping the flames while landing on top of the creature. Commanding all 7 light swords to engage the stone found on the center dragon head, Emidas pries the stone loose!`,
                            `Immediately, The Blockchain Trilemma cancels its attack and bows down to its new master.`,
                            `THE BLOCKCHAIN TRILEMMA: “Master Emidas, you are indeed the Chosen One. The one whose heart is completely pure and filled with fire is the only one capable to bring balance to this realm. Let us join you Chosen One. We will serve you unto the ends of the World.”`,
                            `The Trilemma starts to disintegrate into tiny shards before entering into the pitch-black stone. With the stone in his right hand and The Beast in his left, Emidas brings the stone closer toward the center of the sword’s guard, when suddenly, the stone snaps into its rightful place. Power overwhelms the young hero, with every cell in his body being healed at once. His warrior suit instantly repairs itself, but the material feels foreign to Emidas.`,
                            `Fires rage all around him. Too preoccupied during the battle to see the carnage that had developed, Emidas now sees clearly, every bolder cast down from its original position, and in shambles all around him.`,
                            `Knowing he was now prepared to rejoin his comrades on the battlefield, Emidas opens a portal on the ground and leaps through. The portal chirps louder than ever before, the speed at which Emidas travels through space is orders of magnitude higher compared to what he could reach before the Trilemma had fused with The Beast.`,
                            `As the Elrond Warriors approached the Armies of the Twelves from within the Forest, Emidas lands with a violent thunderbolt back onto the field. Even the expressions found on his own villagers’ faces matched the enemy armies’ terrified countenance.`,
                            `But a third person’s expression was equally aghast: the woman in blue flames. She musters all her might to flee the scene as fast as she can. Emidas lets her go, instead choosing to push back the enemy, and check on the rest of the villagers.`,
                            `The enemy scatters once again, and each man scrambles to retreat back to his own home. The Warriors brace to catch every last person, when they hear Emidas’ booming voice, “Let them GO. The day of fighting between our peoples ends now!”`,
                            `2 Weeks Later…`,
                            `Using the new powers bestowed upon Emidas, Elrond recovers quickly. The walls are repaired, the people’s homes restored, and peace has returned to their village.`,
                            `LUCIAN: “What will we do now?”`,
                            `EMIDAS: “I plan to reunite all of Romania. Our people have been fighting over scarce resources for far too long. It is time to usher in a new era of peace and prosperity for all.”`,
                            `SEVER: “What will we do if we are attacked while you are gone?”`,
                            `EMIDAS: “I have gained tremendous ability through my merger with the Blockchain Trilemma…Although I do not yet fully understand the breadth of my powers, I am learning new ways to wield The Beast every day. The light swords can split into many shards — as many as I request. There seems to be no limit to how many I can produce…I will leave a light sword with each household and should danger come for anyone in Elrond, The Beast will notify me and I shall return immediately.”`,
                            `SEVER: “You are saying these light swords never disappear? These energies will protect us at all times?”`,
                            `EMIDAS: “The only condition the power of the Trilemma requires, is that the hearts and minds of the household it is protecting, stay true to Elrond. When the light swords sense there is evil intent, it will disappear and return back to me. With this protection, the village will be safe from harm. I need to meet with the leaders of the other villages, to explain to them why there is no more need to fight. I will show them how to take back time, and thrive like in the days of old. After this is done, I must find out who the woman in blue flames is, and why she appeared at exactly the perfect opportunity to destroy me on the battlefield. We have unfinished business…let us play hero tag.”`,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 6: The Weight of the World',
                        texts: [
                            `Emidas stood before the castle walls of the enemy; the one who had led the other 11 villages to attack against him. The name of the village was called Centraland, but the people of the region have always referred to it as “The Base of All Coins”; for they wielded immense power on all financial operations.`,
                            `EMIDAS (shouting loud and clear): “I’ve come to make peace! As you can see I am alone, and am here to speak with your leader.”`,
                            `After waiting a few moments for an envoy to appear from the gates, Emidas hears a cacophony of whistling air…10,000 arrows let loose into the sky and head straight for our hero.`,
                            `Knowing the attack was no threat to the new sharding abilities of the Beast, Emidas stands still and lets the light swords make quick work of the volley.`,
                            `EMIDAS (murmurs under his breath): “This is useless, I’ve got to go straight to the one in charge.”`,
                            `He opens a portal into a secure location within the castle walls, but he cannot pass through! There appears to be some kind of force field stopping Emidas from breaching the castle walls. With haste, he turns around to try and speak to the other villages.`,
                            `After making his way one by one to the other 11 tribes, Emidas is only able to successfully convince 9 of them to join Elrond. He explains to each receptive leader how he has been endowed with great gifts…and that he is able to provide safety and security for any villager whose heart remains true to Elrond…He gathers all of the people found in these allied towns, and along with the villagers of Elrond, he creates a utopia, centered on knowledge, collaboration, and a commitment to excellence. He sets up a marketplace where people can freely exchange goods and services, without heavy taxation and overhead. In time, the village of Elrond thrives and turns into a nation of its own.`,
                            `One day when Emidas was busy reviewing construction plans in his chambers, the hairs on the back of his neck stand up straight. The papers in his room began to be blown around by a powerful aura, and 3 figures dressed in robes appeared around him.`,
                            `THE ELDER WIZARD: “Emidas…at long last we meet.”`,
                            `EMIDAS: “By what right do you enter a man’s chambers without his permission? Identify yourselves, NOW.”`,
                            `THE WARRIOR WIZARD: “Watch your tongue boy. You and all your people would have been crushed by the armies of the twelve had we not intervened!”`,
                            `Suddenly it dawns on him, “W-wait…what?…huh?…was it the three of you that sent me the letter that led me to the Beast?”`,
                            `THE ALL SEEING WIZARD: “Emidas we would have preferred to stay out of human affairs, but the enemy left us no choice. A grave threat has their eyes on you, for you threaten the very existence of the powers that be. They now realize the Chosen One has finally appeared to challenge their reign over all mankind.”`,
                            `Through the night, these mystical beings explain the greater war at large. They tell Emidas he must gather Earth’s strongest warriors, and prepare humanity for a massive future event.`,
                            `THE ALL SEEING WIZARD: “By the year 2022 the enemy will have amassed enough power to throw the world into chaos. You must use your light shards to position heroes throughout the Earth, and train the next generation of men and women to stand tall against the greatest threat the world has ever known.”`,
                            `EMIDAS: “…2022?…That’s not for another several hundred years, what impact could I possibly have in a war that won’t happen while I am alive?”`,
                            `THE ELDER WIZARD: “Emidas…sweet child…We are truly sorry. It was an undue burden we have placed upon you. When you merged with The Blockchain Trilemma, you received all of its abilities…You cannot age or die until your mission is fulfilled…”`,
                            `EMIDAS: “You mean to tell me… I have to stand by and watch while everyone I have ever known or loved turns to dust?…”`,
                            `THE WARRIOR WIZARD: “You were the only human capable of shouldering this burden without becoming corrupted. We will guide you when you need us most, but we cannot interfere too much with the affairs of men.”`,
                            `<strong>THE THREE WIZARDS IN UNISON: “IT IS UP TO YOU TO SAVE YOUR OWN REALM! YOU MUST DEFEAT THE ENEMY AT ALL COSTS!”</strong>`,
                            `500 years later…`,
                        ],
                    },
                    {
                        title: 'EMIDAS Act 7: All roads Lead To Elrond',
                        texts: [
                            `The years just kept passing by for Emidas.`,
                            `After the ill-fated night where our hero learned he would elude death for an unknown time, he was distraught. To come to terms with knowing everyone he will ever love will one day grow old and pass away was something that stayed with our not-so-young hero for a very long time. Although in the flesh Emidas was only a young man, the mind had lived for hundreds of years beyond that.`,
                            `Always fearing the worst of the Three Wizards’ prophecy, he lived in constant vigilance, keeping watch over all the Earth — or at least the parts which had agreed to live in peace with Elrond. Those who chose to live with malice in their hearts toward their fellow citizen were left without the protective aura of the Light Sword shards. The battle between chaos and order never stopped, and Emidas came to know just how impossible it was for one man to bend the world to his will. He spent much of his days busy developing training facilities at Elrond to prepare the next generation of warriors. He opened up paths for people of all ethnicities and ages to contribute to Elrond’s goal of improving the world one day at a time. Education and decentralized decision-making became the pillars of their new society.`,
                            `Eventually, all the nations around the world turned to Emidas to seek solutions to conflicts, disasters, and famine. People from all over the world marveled at his never-fading youth and ever-growing wit. A man whose natural intelligence was already at the top of the food chain now had the hundreds of years worth of life experience needed to transform that intelligence into true wisdom. He became known as the wisest man in all the lands, yet he took no part in bullying other nations against their wills. As enough time had passed, all roads led to Elrond.`,
                            `But as the forces of good were busy building a better future, the forces of evil were just as preoccupied plotting their own course. The same group that had hired the woman in blue flames to attack Emidas at the Battle for Elrond, secretly plotted for decades, just waiting for the perfect moment to try and destroy Emidas once and for all. They fought with all their might and with all their tools in order to stop the wave of decentralization traveling throughout the Earth. They printed more money, enticed the politicians to become corrupt, and lured the world deeper into a rigged financial slave system. There were more people that clung to the old world, rather than join Elrond and her allies in freedom. Some people enjoy the security of being part of the system, and feared decentralization as a disruption to the things they held dear…`,
                            `<blockquote><strong>SOMETIME IN THE YEAR 2015, A LARGE METEOR ENTERS THE EARTH’S ATMOSPHERE AND LANDS IN ANTARCTICA…</strong></blockquote>`,
                            `Emidas arrives at the scene.`,
                            `To his right side, a small dog lands in the clearing, and to his left the woman in blue flames appears shortly after, riding atop a glistening Unicorn.`,
                            `ETHER: “Whatever comes out of that thing we’ve got to stop at all costs. They’re here.”`,
                            `EMIDAS: “It’s you! Who?! Who is here? Is this dog with you?”`,
                            `The little Shiba Inu can only stare at Emidas with an excited look. Emidas could feel the weight of Ether’s words…It’s as the three wise wizards told him all those years ago: The Chosen Ones had arrived. Emidas reaches out his hand and the Beast appears swiftly. He opens a portal to charge closer to the center of the crash site when suddenly he is teleported back to the throne room and frozen under a spell. He cannot speak, he cannot move — he is utterly frozen.`,
                            `A tall, muscular, beautiful creature rises out from the ashes with a finger pointed where Emidas stood just a few seconds before.`,
                            `ETHER: “Um where did he go?!”`,
                            `Again the Shiba Inu can only look at Ether with an excited look.`,
                            `SUPREME VILLAIN: “I can use that ability once upon landing on a new planet. Quite handy actually. It pins down the strongest warrior found on that planet. I’m guessing that was him. He won’t be joining us anymore.”`,
                            `ETHER: “So you can only use that trick once?”`,
                            `When the Villain realizes what our heroine is actually saying, Ether wraps the Shiba Inu in a sheet of blue flames and has the Unicorn swap their location to somewhere safe. The trio just barely makes it out with their lives intact.`,
                            `ETHER: “We need to gather everyone. They’re here.”`,
                        ],
                    },
                ],
                aermes: [
                    {
                        title: 'AERMES Act 1: Lift-Off The Charts',
                        texts: [
                            `COACH WARD: “That boy sure is fast, but he’s dumb as rocks ain’t he?”`,
                            `ASSISTANT COACH JENKINS: “Sure he seems a little slow, but when you watch him play, he’s got an intuition that’s literally off the charts. I wouldn’t be so sure to write him off.”`,
                            `Aermes, having the gift of keen hearing ever since he could remember, let’s loose a smirk.`,
                            `ASSISTANT COACH JENKINS (surprised): “What the f-….you don’t think he heard us do you?”`,
                            `It’s been like this since the early days. Back when he was just a young boy playing on the swing set, the others kept their distance and levied what they considered humorous insults at his expense. Aermes never smiled with his eyes, only ever smirking at things he found beneath him. The other kids found this robotic, in a way that left everyone around him feeling uncomfortable.`,
                            `You see, Aermes was no ordinary boy. He was what they called gifted. While others would frolic and laugh with all the purity that childhood holds, he was tormented with a much different experience: the burden of extreme intelligence. Although he kept himself preoccupied with building all manner of inventions, he did not lie to himself that his early childhood was anything other than that of extreme loneliness. He tried reasoning with himself, hating the very simple fact that someone of his ability would even be bothered by something so frivolous as perception. The reality of what he knew to be true and just fought with his inner turmoil. Depression became his only consistent friend for much of his young life.`,
                            `On the outside, Aermes took a jaded pleasure in behaving as someone who was mentally handicapped. He would stare blankly as his classmates surrounded him and asked him simple questions.`,
                            `“Hey Freak! What’s 1 + 1?!”`,
                            `“Aermes! Do you know how to spell your own name?!”`,
                            `“When’s the last time you spoke a full sentence loser?!”`,
                            `At home, it wasn’t much different. His family never embraced his gifts either. His 12 siblings regularly joined in the mockery, and our young hero Aermes only found solace in a secret room he had built underneath the crawlspace. Nobody noticed the meals he skipped, or perhaps they never cared. Aermes would lock himself in this room for hours on end, reading books on subjects ranging from quantum physics to international political game theory. Books became his second-best friend.`,
                            `Eventually, the boy turned into a teenager, and with it, developed all the tell-tale signs of puberty-induced aggression. Aermes (ever the one to self-diagnose a problem with startling accuracy) knew the least harmful path to stabilize his brain chemistry was to get involved in physical activity: so he chose soccer. He trained all summer long, reading the autobiographies of the great players that came before him, in order to concoct a training regiment that would ensure he would become the top athlete in his school. He trained until his feet bled, his toenails fell off, and he became as fast as a gazelle.`,
                            `As a freshman (grade 9) in high school, Aermes dominated the pitch, breaking and setting State records with each game he played. Soon, the very same people who bullied him befriended him, and the entire school took pride in all of his accomplishments. However, he never saw his parents or his siblings at any of the games. And through all the attention he received, he could not help but feel contempt, never feeling like the current day perception could ever wipe away a childhood filled with insult.`,
                            `As the season came to an end, with Aermes winning the State Championship as the MVP, the school decided to host a banquet for the soccer team. They sent invites to all of the parents, and for once in his life, Aermes looked forward to seeing how his family would react once he received honors on stage. After putting on his suit jacket, and heading out of his secret room to join the rest of the family, he noticed that everyone had already left the home. Finding it strange that they would leave without him, he looked around the house for indicators as to why they would have left early. What he found was a flyer on the kitchen table for the county fair that was taking place that very same night.`,
                            `Dejected and filled with anger, he ran into his laboratory. He turned on the high-density particle accelerator and brought to life an invention he had been working on for the past 5 years.`,
                            `The four rings of flight were brought to life. Placing each one on his hands and legs, he commands them to take flight. He struggles to find his balance, failing time and time again — until he finally gains control. Feeling as if what lies ahead was worlds better than what lay behind, Aermes takes flight and runs away for good.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 2: Flight or Fight',
                        texts: [
                            `Aermes has been a wanted man, actually, still just a 16-year-old boy for roughly a year now.`,
                            `When he fired up his particle accelerator, he ended up causing millions of dollars in damages to the power grid. The federal authorities were not too pleased, to say the least. The Pentagon and every military agency within the United States were after the technology, or at least what remained when they sent agents to scour and search every nook and cranny of his family’s home. They were stunned to find trace evidence of what appeared to be technological and theoretical quantum mechanic discoveries novel to scientific communities. Before asking how a 9th-grade soccer phenom could develop all of this within the crawlspace of his home, they find the hundreds of books he kept neatly organized in the attic. His family had no idea those books were even there, or how Aermes had the money to acquire them. They never gave him an allowance and he never asked for any cash to buy things either.`,
                            `You could say Aermes has always had a knack for making money. Though others saw a disinterested teenager with headphones on, eyes glued to his phone, the reality was that Aermes had been running his own mechanical engineering consulting firm for years. Using alter egos, fake physical office addresses, and conducting all his business through emails, he was now advising several dozen of the world’s leading military, aeronautic, and hadron collider research facilities across the world. He had split his current net worth of $21 million between several accounts, using several identities, across several different asset classes. This was not your typical case of a neighborhood kid running a lemonade stand or grass-cutting business. Aermes took childhood entrepreneurship to an entirely different level.`,
                            `Without school and sports, Aermes took to his passions full time. Flying all over the world, often from when the sun rose until the sunset fell, he experienced a time of his life where freedom came to this caged bird, like sight to the blind. A true smile was seen on his face from time to time as he soared through the skies, exploring the world and all that nature had to offer.`,
                            `Every so often the authorities would catch up and serendipitously run into Aermes. With each passing day, the boy becomes more infused with the ability to control his Flight Rings. His top 2 rings can be used as posterior thrusters, while his bottom 2 rings stay stabilizing his foot positions. He easily outmaneuvers even the fastest fighter jets, all while taking tablet notes on Q Level locked aircraft designs.`,
                            `One day while coming up on three fighter jets and approaching them from the rear, he hacks one of the jet’s electrical control systems and ejects the pilot out of the seat.`,
                            `“Don’t mind if I do,” he says while he enters the cockpit and takes control of the jet.`,
                            `As the other fighters set their targeting on Aermes’ newly “acquired” aircraft, they blink, before our hero’s Flight Rings have propelled the craft so far away from the pursuers, that they quickly lose sight of him.`,
                            `That’s when the hairs on their necks stand up. The radar shows an aircraft on their current position, closing in from the rear. A plane jets by them, wind and clouds swirling all around.`,
                            `Within the chaos, for the briefest of moments, they see the stolen plane suddenly flash in front of them, completing a supermanuever Kulbit that should not have been physically possible!`,
                            `The boy appears to be flying the plane backwards, flashing a thumbs-up and that damned deadpan smile. Up, up and away - he’s gone, almost as soon as he arrived.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 3: Flying Closer To The Sun',
                        texts: [
                            `The boy genius continued to spend most of his days alone. After commandeering the Raptor from the U.S. military, he disengaged the tracking system and flew it back to his place. Aermes has been living on the beach in a cliffside mansion he purchased under the alter ego: Ryan Carter.`,
                            `Solitude continues to be his best friend and his darkest passenger. When he’s not training, running, lifting weights, or practicing hand-to-hand combat with his fighter droids, Aermes continues to find solace in his books. Religion, philosophy, international geopolitics, ancient scrolls, and long-lost tales of treasure and conflict…he immerses himself deeper into human history until reality begins to blend more and more with the stories that have been with humanity throughout the years. He reads of Gods and Goddesses and smirks whenever he reads the story behind his namesake Hermes. He puts down the current ancient texts he’s reading to make himself some lunch…on the current page, we see a strange warrior dressed in black and gold with a shield and sword and glowing eyes of pent-up rage.`,
                            `As he eats his turkey, roast beef, & ham ‘Aermes Special’, he receives an email from none other than his little sister. She writes to him and asks if he is doing ok…that the family was shocked to find out the contents of his crawlspace secret room…and that nobody knows who he is anymore…she writes that the CIA and FBI will not leave their house alone. Watchmen have been stationed at their schools, places of employment, and still, their suburban home.`,
                            `AERMES: “These fuckers got nothing better to do than to harass them? I’ll show them what harassment looks like.”`,
                            `The plot begins for Aermes to craft his greatest invention yet: a full-body suit that will conceal his identity, distance himself from his family, and combine every cutting-edge technology he had been working diligently on for the past several years.`,
                            `AERMES: “I’ll need stronger stabilization. Posterior thrust and steady-state deceleration need to be prioritized”, he mutters to himself.`,
                            `He takes his jet to a high altitude and begins his tests.`,
                            `Into the military-industrial complex our young hero goes…flying closer to the sun.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 4: When The Student Is Ready',
                        texts: [
                            `543 days.`,
                            `It had been 543 days since Aermes last left his laboratory. We knew the solitude was nothing unknown for him, yet the boy genius still found himself in a raging battle against his own sanity.`,
                            `Ever since becoming aware of the potential that his path could put his family in harm's way, the fear, the uncertainty, and the doubt, began to eat away at his conscience.`,
                            `“Fuck ‘em! Why should I care what happens to them?! What did they ever do for me?! We were nothing to them! Nothing!”`,
                            `“You’re too smart for that…you know where this path leads. You’re not going to stop what you’re doing, and the feds know that.”`,
                            `“The Feds, the NSA, the CIA, I’ll scramble their systems so bad they’ll be sent back to 1995!”`,
                            `“Yeah? and then what? Yeah, you fuckin’ genius, what happens after that? You just gonna flash a smile and have it all go away? You’ll be hunted down like a dog the rest of your life.”`,
                            `“Let ’em try me…it’s not like I’ve got anything to lose.”`,
                            `These conversations between himself began a few months back. Even genius was not enough to conquer loneliness. But it was enough, to finish the Flight Suit. Additional flight stabilization was built into every angle of this suit. He had studied every creature that took flight and analyzed millions of movements in his simulators. He developed a fusion between nanotechnology and quantum liquid state hardening to craft a suit that was virtually indestructible. He had been fighting 4 hours a day against his second iteration of fighter droids he dubbed “the v2s”…turning his body into a deadly force itself.`,
                            `He turned in for the night, the lights went out, and he prepared himself for what was to come the next day.`,
                            `Standing tall with his arms by his side, he wears the completed work of art for the very first time. He feels the cool sensation of the nanobots leaving his 4 rings and coating his entire body. He checks his watch one last time and notices an insignificant detail, “it’s my birthday…”, before flying out.`,
                            `As he approaches Langley, Virginia, he scans the entire complex before locating the route to the subterranean database structures. Knowing once he begins his descent, there is no coming back, he dives. While racing like a missile shot out of hell, he hears a chirping sound in his ears. His flight rings power down and he can no longer fly. He’s blinded and begins tumbling through the air. Light flashing all around him, he’s hurled down, down, down.`,
                            `His body hits a soft padded mat on the floor. Dazed and confused he covers his eyes and seeks to reorient his sense of balance.`,
                            `EMIDAS: Get your ass up. And fight me.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 5: The Teacher Appears',
                        texts: [
                            `AERMES: “Wh….Who are you?! What happened to me?!”`,
                            `EMIDAS: “I said…Get. Your. Ass. UP.” (His eyes glowing with intensity)`,
                            `Aermes, unsure of what to do, stretches out his palms and attempts to lift off.`,
                            `EMIDAS: “That’s not going to fly here. There’s only one way out of here. FIGHT.”`,
                            `AERMES: “You fucked with the wrong one today. I don’t have any problems with you bro, but if I need to mess you up before you let me out of here, just remember, you asked for this shit.”`,
                            `Emidas beams a warm, gentle smile. He walks slowly toward the kid. With each passing step, one by one, his weapons appear. First The Beast appears in his right hand. Next, his shield materializes on his left arm. And finally, seven shards of light encircle both warriors, with Aermes’ eyes growing wider by the second.`,
                            `Aermes brings his rings together to the front, with his Visor controlling every movement with elite precision. It appears as if his rings still have life! Unable to fly, he digs his heels into the floor and enters his fighting stance. The two begin their battle.`,
                            `The room fills with the sounds of metal clashing against each other. Both fighters supremely skilled in all manner of hand-to-hand combat.`,
                            `Emidas switches between fighting styles. He changes stances as if he were flipping through a catalog. He tests the boy one by one. Aermes can barely manage to avoid damage to his vital areas, but somehow he keeps pace with his aggressor. Time appears to be frozen but he feels as if they have been fighting for days. Emidas slashes The Beast across his body, right to left, turning his hips with expert leverage, breaking all four rings at once.`,
                            `He throws his shield and sword aside and raises his fists, taunting the boy to come at him. His eyes twinkle before allowing Aermes to get in a solid punch to his ribs. The boy’s eyes widen again. He smells blood in the water and begins pummeling Emidas with every combinatory attack in his arsenal.`,
                            `He sends right after left, square onto Emidas’ face. He doesn’t realize how long he’s been punching before finally noticing his opponent has gone unconscious.`,
                            `He raises his fist back behind his shoulder and prepares to end the battle for good.`,
                            `AERMES: “I didn’t start this man…why’d you have to do this...”`,
                            `Just then, the boy remembers the page left open on his toolbox.`,
                            `His eyes…widen again.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 6: Reborn',
                        texts: [
                            `Aermes stumbles backward.`,
                            `Emidas gets up and smiles, before dusting himself off. His suit and body heal in an instant.`,
                            `EMIDAS: “Not bad kid. Not bad.”`,
                            `AERMES: “You…you’re…I saw you…in an ancient scroll, I found deep within the mountains…Wait…is that really you? How is any of this possible?”`,
                            `EMIDAS: “I’ll explain everything. Follow me.”`,
                            `Emidas opens up a portal and walks through. As if obeying a feeling he could not possibly ignore, Aermes follows right behind him without missing a beat…`,
                            `What awaited Aermes was a sight to behold. They stepped through the portal and into a strange land.`,
                            `EMIDAS: “This is my home. Welcome, to Elrond.”`,
                            `AERMES: “Am…I dead?”`,
                            `EMIDAS (laughing): “On the contrary my boy…you are very much alive.”`,
                            `The two walk through the crystal-lined streets toward a tall tower at the center of a grand plaza. Everywhere they go, the people stop and place their hands together in thanks towards their King. The young boy’s face is caught in pure amazement. He cannot bring his lower jaw to close, mouth hanging dumbfoundedly open as his head turns from side to side. He marvels at the structures, the technology, the peace, and the serenity. There is flowing water along both sides of the main street, and when he stops to place his hands inside it, the water glows with luminosity.`,
                            `They make their way to the tower (or was this a castle?). There are no doors in this place. Only the breeze of a slight, pleasant wind, seemingly brushing the leaves of the nearby magnificent plants with tender care.`,
                            `RECEPTIONIST: “Welcome home sir. I take it your trip was successful. The boy is even more dashing than we had imagined.”`,
                            `EMIDAS (chuckling softly): “Do not tell him that. He’s got enough confidence to last a few lifetimes.”`,
                            `Making their way to the end of the foyer, they enter the elevator.`,
                            `AERMES: “Hey why are we taking the elevator? Can’t you just teleport us up? I’ll be honest, I really want to try that again.”`,
                            `Emidas lets loose the faintest of smirks.`,
                            `EMIDAS: “There is something I want to show you.”`,
                            `The elevator doesn’t make a sound as it ascends toward the throne room. As it rises, Aermes loses his composure again and his eyes begin to water. What he sees before him is the Kingdom of Elrond in all its glory. With the Carpathian Mountains enclosing the village, his eyes look upon the most beautiful city he has ever seen: skyscrapers with waterfalls pouring down the sides…vertical farms seen visible through the floor-to-ceiling glass windows in the distance…and do his eyes deceive him? He could have sworn he saw the whisper of a massive winged creature, high above the clouds.`,
                            `AERMES: “You’re him aren’t you? You’re King Emidas…”`,
                            `EMIDAS: “Unfortunately, I am.”`,
                            `The elevator comes to a frictionless pause. The two men leave the platform and enter the throne room of the King.`,
                            `EMIDAS: “Aermes, why do you think you are here?”`,
                            `Having been caught up in the events of the entire day, he realizes he has not even asked himself the most basic of questions. Suddenly, he remembers what he was doing before the first portal relocated him into that dojo.`,
                            `AERMES: “I was going to do something really stupid. I was about the cross the line.”`,
                            `EMIDAS: “Yes…and no. You were about to do something very stupid, that is true. But you did not land the final blow against me. You paused against a foreigner who had every indication of being your enemy. I have been searching for many years. Many have come and gone and yet I remain.”`,
                            `AERMES: “Searching for who…?”`,
                            `EMIDAS: “I need warriors. They will soon be here at last.”`,
                            `AERMES: “Who?”`,
                            `EMIDAS: “Follow me.”`,
                            `They make their way to a large conference room made of glass: glass floors, glass walls, and glass ceilings. Upon their entry, the panels on the ceiling disappear, inviting the warm sun and the cool breeze into the room.`,
                            `EMIDAS: “Did you ever wonder why your family always kept their distance from you?”`,
                            `AERMES (eyes widening, before quickly looking away): “Sir…I’d rather not talk about this.”`,
                            `EMIDAS: “Perhaps another time then.”`,
                            `Just then, a group of chefs enters the room bringing an array of delicacies from every nation. Emidas and Aermes share a meal. The boy cannot remember the last time he has a meal like this. In fact, he could not remember the last time he had a warm meal. The two shared war stories, with the boy incessantly asking questions about historical figures and who those people really were. Emidas doesn’t seem to mind. The two talk for hours before Emidas stands up from the table.`,
                            `EMIDAS: “It is getting late. I am sure you are tired. We have a room prepared for you. Go and get some rest.”`,
                            `AERMES: “Why are you doing all of this for me? Is there some kind of occasion?”`,
                            `Feeling emotions he never knew he had, Aermes weakens as a single tear rolls down his face, and politely excuses himself. When he arrives in his suite, there is a box neatly wrapped on his bed. On it, is a card that reads, “To the boy who has never been understood: Your story is still being written.”`,
                            `He places the card aside to reveal a box within the box. This one is made of a strange alloy he has never seen before. He opens the box and finds four new Flight Rings and a new visor; different than the ones he had before. He activates his visor and commands the rings to find their places on his wrists and ankles.`,
                            `The nano suit he was wearing is replaced with new material. His body begins to glow. He looks at himself in the mirror and to his surprise, he sees something he has never seen before:`,
                            `a true smile.`,
                        ],
                    },
                    {
                        title: 'AERMES Act 7: A Hero Is Born',
                        texts: [
                            `Aermes awakens to sirens blaring all around him.`,
                            `He leaps out of bed when suddenly, his Four Rings snap directly onto his wrists and ankles. The Flight Suit instantly activates as if sensing the danger imminently inbound. He doesn’t run. Instead, his suit thrusts him forward, down the halls before leading him to Emidas’ throne room.`,
                            `He can see Emidas sitting still, right hand placed on his sword, staring blankly ahead.`,
                            `AERMES: Sir…?`,
                            `Emidas remains frozen in place.`,
                            `AERMES: SIR!`,
                            `Still, the King shows no movements.`,
                            `AERMES: “What the fuck is going on here…King Emidas do you not hear the sirens?!”`,
                            `He looks around to find any signs as to what is unfolding before him. To his left, he sees a wall full of holographic monitors. Several news stations report that an “8.7 EARTHQUAKE ROCKS THE WEST COAST”, as the caption glides on repeat across the screen.`,
                            `Soldiers and Generals enter the room. They see their King fixated on seemingly nothing before turning their gaze towards Aermes.`,
                            `GENERAL BACIU: “STOP WHERE YOU ARE.”`,
                            `AERMES: “WAIT, I KNOW HOW THIS LOOKS. I GOT HERE A FEW SECONDS BEFORE YOU GUYS, HE’S STUCK, HE WON’T MOVE!”`,
                            `The men move closer in circle formation, arms at the ready. Aermes turns his head towards the sky, rings rotating faster and faster. The soldiers fire sedation rounds, but just before the darts reach their target, his Rings spin rapidly, creating a gust that renders the attacks useless. When the wind subsides and the men regain their vision, and the boy is gone.`,
                            `When he reaches Los Angeles, what he finds is utter chaos. Buildings crumbling, people hanging off balconies…the cries for help seem to come from everywhere at once — flames and smoke are burning through the night sky.`,
                            `He springs into action. Without hesitation he soars between the skyscrapers, catching falling civilians and using his upgraded Flight Rings to put out the flames. His visor has upgraded features as well. They tell Aermes the exact flight path needed to minimize the loss of life. He works through the night without rest, and when the morning dawn approaches, with his body barely able to move, he begins his descent, to finally touch the ground after 10 straight hours in the air.`,
                            `People look upwards and the crowd erupts into thunderous applause. He looks around to see mothers reunited with their children and fathers holding back tears with countenances full of appreciation.`,
                            `“YOU SAVED US!”`,
                            `“WHAT IS YOUR NAME, SON?!”`,
                            `“THANK YOU!”`,
                            `What is this feeling growing inside his heart? What is this emotion that he has never felt before? But the people around him know full well what he has done, their hearts singing in gratitude for this young man’s bravery. The smartphones begin to record as he gets closer and closer to the ground. And just like that…`,
                            `A hero is born. `,
                        ],
                    },
                    {
                        title: 'AERMES Act 8: Go And Find Them',
                        texts: [
                            `Word traveled fast, almost as fast as the boy genius himself.`,
                            `Social media, Youtube, Streamers, Mainstream Media, everyone was buzzing about the masked teenager who flew all through the night, rescuing people and putting out fires seemingly all over the place. Fans pieced together all of the camera footage from that night, and actually re-created his entire flight sequence, calculating that his efforts alone were single-handedly responsible for saving 28,431 lives directly, and another 25,000 in potential casualties. Murals went up all throughout Los Angeles, and the big question on everyone’s mind was: Who is this kid?`,
                            `Back resting in his cliffside retreat, Aermes was pleased to see that the surveillance devices he placed around his family’s home reported a drastic decrease in the level of suspicious activity. It seems even those in the highest levels of the US Military-Industrial Complex found appreciation in what Aermes did that night.`,
                            `Still…the question that would eat away at young Aermes was why King Emidas was frozen that morning in the throne room? Did the earthquake in LA have anything to do with what he saw in the City of Elrond? Should he fly back to Elrond to check up on Emidas? Suddenly, an email arrives with a video attachment:`,
                            `EMIDAS: “Listen kid if you’re watching this, that means things have already begun. Remember what we talked about right? We need to assemble the A team. Look…uh…it has been a while since I last spoke with them…turns out waiting for an enemy that does not give you their arrival date is a tough pitch to sell to Earth’s mightiest warriors. Tell them this is not a drill and to activate Phoenix Protocols immediately. Make sure to find Ether, she can get me out. God Speed kid. No pressure, but Earth’s fate depends on it. Go and find them.”`,
                        ],
                    },
                ],
                doga: [
                    {
                        title: 'DOGA Act 1: Do Only Good Always',
                        texts: [
                            `Do all dogs dream? This one sure does. Sometimes it wonders if its dreams are real or if they’re things that have happened to it in the past. Every night she wishes tonight would be the night she has one of her good dreams.`,
                            `In some dreams she's with her best friend; Her master. She sleeps tucked underneath his armpit, they go to the park often, and even sit together calmly while watching the duck families float across the pond.`,
                            `In other dreams, it’s darker. Much fear. She recalls being taken into the air by bright lights and seeing tiny humans with gray skin and big eyes. She can remember smelling but not seeing; hearing but not feeling.`,
                            `The good thing about living in Tokyo was that she never found enough peace and quiet to get much unbroken sleep. Between the car horns, trains, buses, construction workers working at all hours of the day, and thousands of conversations happening all at once, good sleep was a luxury our furry friend did not get to have.`,
                            `You see, this particular puppy had been living in a small alleyway, underneath a stack of old restaurant crates the landlord never bothered to move elsewhere. She can’t quite remember how she arrived there or where she even came from.`,
                            `The bustling energy of the city slowly but surely washes the residual memories of the dream away, but one thing continues to resonate within the young pup each and every day. She can hear a human voice telling her her favorite words. Every time her human would say the words, she remembered how fast her heart would beat, and how wildly her tail would wag. “Do only good, always,” her master would say. “Even when the world tells you otherwise, do only good always.”`,
                            `So good, the dog would do.`,
                            `Each morning the pup would begin her patrol around the city. Though young and frail with very little to eat, the dog’s spirit was not to be taken lightly. Tales are told today of those early days, when powerless Doga would save others time and time again.`,
                            `About the time she saw an injured bird lying behind the dumpster. Doga stayed cuddled next to that bird for 6 days, fending off anything and anyone that even got close during its recovery.`,
                            `Or perhaps the time a crying child was lost in a park, and Doga came over to calm the child down. After spending a few moments building trust, the dog slowly walked the child back to the trail, where the parents were desperately searching high and low to find their son.`,
                            `And who can forget when she saw a young woman walking home in the rain late at night. She sensed a dark figure with evil intent following close behind. Doga bit the man’s hand the moment he moved to attack the woman. It sank its teeth into the man’s fingers before leaping for his face, gnawing at whatever it could latch onto. The young woman ran away safely.`,
                            `She found that doing good felt good. That it gave her purpose and a sense of connection with others. But she also found that mankind was full of darkness. Each act of goodness introduced into Doga’s life an act of evil. When she saved the injured bird, it was because she saw a young human violently swatting the bird down as it got too close to their food. The child who had been crying in the park was also being followed by an old man with a pocket full of sweets. The hand she bit to save the young woman ended up beating young Doga with pent-up rage.`,
                            `Doing only good always seemed to be something not everyone else agreed to follow. The night she saved that woman, she limped home and crawled into her hiding spot.`,
                            `She gets her favorite bone. The one that’s always been by her side. She walks back out into the pouring rain, and suddenly barks, shocked at another dog she just noticed nearby!`,
                            `She stares at the other dog for a minute and starts to wag her tail. The other dog returns the kindness with its own tail wag sent back. Thinking she’s made a friend, she smiles wide and walks closer to smell the new dog.`,
                            `Alas, it was merely its own reflection in the puddle below. She saunters back under the crates and whimpers softly. Tears hidden among the raindrops, she whispers to herself, “I hope tonight I will get to have one of my good dreams.”`,
                        ],
                    },
                ],
            },
        },
        roadmap: {
            roadmap: 'Roadmap',
            roadmaps: [
                {
                    name: 'Q1',
                    items: [
                        ['Exchange Listing', 'NFT Collection Announcements'],
                        ['Launchpad Announcement', 'First Staking Rounds'],
                    ],
                },
                {
                    name: 'Q2',
                    items: [
                        [
                            'Emidas Act 8 Release',
                            'Neo Project Unveil',
                            'Second Staking Rounds',
                        ],
                    ],
                },
                {
                    name: 'Q3',
                    items: [
                        [
                            'AERMES Act 2 Release',
                            'Comics Integration',
                            'Metaverse Specifications Release',
                        ],
                    ],
                },
                {
                    name: 'Q4',
                    items: [
                        [
                            'Zilliqa Bridge',
                            'Third Staking Rounds',
                            'Trading Game Release',
                        ],
                    ],
                },
            ],
        },
        footer: {
            gnogen: '2022 GNOGEN',
            allrights: 'ALL RIGHTS RESERVED.',
            terms: 'TERMS & CONDITIONS',
            privacy: 'PRIVACY',
            disclaimer: 'DISCLAIMER',
        },
    },
    fr: {
        header: {
            profile: 'Profil',
            logout: 'Déconnexion',
            entries: 'Entrées',
            admin: 'Administration',
        },
    },
});

export default strings;
