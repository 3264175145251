import * as React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LightLayout from '../layouts/LightLayout';
import GoldLayout from '../layouts/GoldLayout';
import DarkLayout from '../layouts/DarkLayout';
import Home from '../pages/Home';
//import Roadmap from '../pages/ScrollRoadmap';
//import ThreeSandbox from '../pages/ThreeJSSandbox';
import About from '../pages/About';
import Elrond from '../pages/Elrond';
import Collections from '../pages/Collections';
import CollectionRoute from '../pages/CollectionRoute';
import './styles.scss';

const Routing = () => {
    const location = useLocation();
    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Routes location={location}>
                    <Route path="/">
                        <Route element={<LightLayout />}>
                            <Route index element={<Home />} />
                            <Route exact path="/about" element={<About />} />
                            <Route
                                path="/collections"
                                element={<Collections />}
                            />
                        </Route>

                        <Route element={<GoldLayout />}>
                            <Route path="/elrond" element={<Elrond />} />
                        </Route>

                        <Route element={<DarkLayout />}>
                            {/*<Route path="/roadmap" element={<Roadmap />} />
                            <Route path="/sandbox" element={<ThreeSandbox />} />*/}
                            <Route
                                path="/collections/:id"
                                element={<CollectionRoute />}
                            />
                            <Route
                                path="/collections/:id/:act"
                                element={<CollectionRoute />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};

const Router = () => {
    return (
        <BrowserRouter>
            <Routing />
        </BrowserRouter>
    );
};

export default Router;
