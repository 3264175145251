import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

function DisclaimerModal(props) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={props.handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full z-50 max-h-[80vh]">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-roboto">
                                            <Dialog.Title
                                                as="span"
                                                className="text-6xl font-gnogen text-shadow font-medium text-gray-900 flex items-center justify-between mb-4">
                                                Disclaimer
                                                <div
                                                    className="flex items-center justify-center h-12 w-12 rounded-full"
                                                    onClick={props.handleClose}>
                                                    <XIcon
                                                        className="h-6 w-6 text-black"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                {/* THE FOLLOWING DIV WAS GENERATED FROM A WORD FILE WITH THOSE 3 STEPS:
                                                 *** 1- COPY THE WORD DOCUMENT CONTENT INTO THIS WEB TOOL: https://wordtohtml.net/
                                                 *** 2- COPY THE RESULT INOT THIS MINIFIER TOOL: https://beautifytools.com/html-minifier.php#:~:text=Online%20HTML%20Minifier%20removes%20all,absolute%20URLs%20to%20relative%20URLs.
                                                 *** 3- COPY THE RESULT INTO THIS HTML -> JSX CONVERTER: https://magic.reactjs.net/htmltojsx.htm
                                                 *** THE RESULT IS LARGER THAN SHOULD PROBABLY BE BUT TAKES WAY LESS TIME TO GENERATE THAN MANUALLY RE-TYPING THE DOC
                                                 */}
                                                <div>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                Gnogen Studios
                                                                Disclaimer
                                                                &nbsp;
                                                            </span>
                                                        </strong>
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily: '"',
                                                        }}>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                lineHeight:
                                                                    '115%',
                                                                fontFamily:
                                                                    'Roboto',
                                                            }}>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            lineHeight: '115%',
                                                            fontSize: '15px',
                                                            fontFamily:
                                                                '"border:none',
                                                            borderBottom:
                                                                'none windowtext 1pt',
                                                            padding:
                                                                '0 0 11pt 0',
                                                        }}>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    DISCLAIMER
                                                                </span>
                                                            </strong>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    &nbsp;
                                                                </span>
                                                            </strong>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                By using the
                                                                site, you
                                                                understand and
                                                                agree that you
                                                                are accessing
                                                                the site at your
                                                                own risk and
                                                                that the site is
                                                                provided as is
                                                                and without
                                                                warranties of
                                                                any kind. It is
                                                                your sole
                                                                responsibility
                                                                to use proper
                                                                procedures and
                                                                antivirus
                                                                protection that
                                                                meet your
                                                                personal
                                                                standards. You
                                                                understand that
                                                                we will never
                                                                guarantee that
                                                                any files made
                                                                available to you
                                                                for download on
                                                                the internet,
                                                                the site or any
                                                                third party
                                                                sites will be
                                                                free of any
                                                                viruses or
                                                                destruction
                                                                codes.&nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                        lineHeight:
                                                                            '115%',
                                                                        fontFamily:
                                                                            'Roboto',
                                                                    }}>
                                                                    &nbsp;
                                                                </span>
                                                            </strong>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '115%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '115%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                You agree that
                                                                we have no
                                                                liability or are
                                                                responsible for
                                                                any breach of
                                                                security unless
                                                                it is due to our
                                                                willful
                                                                misconduct.{' '}
                                                                <strong>
                                                                    <br />
                                                                    &nbsp;
                                                                </strong>
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                TO THE FULLEST
                                                                EXTENT PROVIDED
                                                                BY LAW, WE WILL
                                                                NOT BE LIABLE
                                                                FOR ANY LOSS OR
                                                                DAMAGE CAUSED BY
                                                                A DISTRIBUTED
                                                                DENIAL-OF-SERVICE
                                                                ATTACK, VIRUSES,
                                                                OR OTHER
                                                                TECHNOLOGICALLY
                                                                HARMFUL MATERIAL
                                                                THAT MAY INFECT
                                                                YOUR COMPUTER
                                                                EQUIPMENT,
                                                                COMPUTER
                                                                PROGRAMS, DATA,
                                                                OR OTHER
                                                                PROPRIETARY
                                                                MATERIAL DUE TO
                                                                YOUR USE OF THE
                                                                SITE OR ANY
                                                                SERVICES OR
                                                                ITEMS OBTAINED
                                                                THROUGH THE SITE
                                                                OR TO YOUR
                                                                DOWNLOADING OF
                                                                ANY MATERIAL
                                                                POSTED ON THE
                                                                SITE, OR ON ANY
                                                                WEBSITE LINKED
                                                                TO THE SITE.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                YOUR USE OF THE
                                                                SITE, ITS
                                                                CONTENT, AND ANY
                                                                SERVICES OR
                                                                ITEMS OBTAINED
                                                                THROUGH THE SITE
                                                                IS AT YOUR OWN
                                                                RISK. THE SITE,
                                                                ITS CONTENT, AND
                                                                ANY SERVICES OR
                                                                ITEMS OBTAINED
                                                                THROUGH THE SITE
                                                                ARE PROVIDED ON
                                                                AN “AS IS” AND
                                                                “AS AVAILABLE”
                                                                BASIS, WITHOUT
                                                                ANY WARRANTIES
                                                                OF ANY KIND,
                                                                EITHER EXPRESS
                                                                OR IMPLIED.
                                                                NEITHER GNOGEN
                                                                STUDIOS, ITS
                                                                AFFILIATES,
                                                                THEIR LICENSORS
                                                                OR SERVICE
                                                                PROVIDERS, OR
                                                                ANY PERSON
                                                                ASSOCIATED WITH
                                                                ANY OF THEM,
                                                                MAKES ANY
                                                                WARRANTY OR
                                                                REPRESENTATION
                                                                WITH RESPECT TO
                                                                THE
                                                                COMPLETENESS,
                                                                SECURITY,
                                                                RELIABILITY,
                                                                QUALITY,
                                                                ACCURACY, OR
                                                                AVAILABILITY OF
                                                                THE SITE.
                                                                WITHOUT LIMITING
                                                                THE FOREGOING,
                                                                NEITHER GNOGEN
                                                                STUDIOS, ITS
                                                                AFFILIATES,
                                                                THEIR LICENSORS
                                                                OR SERVICE
                                                                PROVIDERS, OR
                                                                ANY PERSON
                                                                ASSOCIATED WITH
                                                                ANY OF THEM,
                                                                REPRESENTS OR
                                                                WARRANTS THAT
                                                                THE WEBSITE, ITS
                                                                CONTENT, OR ANY
                                                                SERVICES OR
                                                                ITEMS OBTAINED
                                                                THROUGH THE
                                                                WEBSITE WILL BE
                                                                ACCURATE,
                                                                RELIABLE,
                                                                ERROR-FREE, OR
                                                                UNINTERRUPTED,
                                                                THAT DEFECTS
                                                                WILL BE
                                                                CORRECTED, THAT
                                                                THE WEBSITE OR
                                                                THE SERVER THAT
                                                                MAKES IT
                                                                AVAILABLE ARE
                                                                FREE OF VIRUSES
                                                                OR OTHER HARMFUL
                                                                COMPONENTS, OR
                                                                THAT THE SITE OR
                                                                ANY SERVICES OR
                                                                ITEMS OBTAINED
                                                                THROUGH THE SITE
                                                                WILL OTHERWISE
                                                                MEET YOUR NEEDS
                                                                OR EXPECTATIONS.
                                                                TO THE FULLEST
                                                                EXTENT PROVIDED
                                                                BY LAW, GNOGEN
                                                                STUDIOS, ITS
                                                                AFFILIATES, AND
                                                                THEIR LICENSORS
                                                                AND SERVICE
                                                                PROVIDERS,
                                                                HEREBY DISCLAIM
                                                                ALL WARRANTIES
                                                                OF ANY KIND,
                                                                WHETHER EXPRESS
                                                                OR IMPLIED,
                                                                STATUTORY, OR
                                                                OTHERWISE,
                                                                INCLUDING BUT
                                                                NOT LIMITED TO
                                                                ANY WARRANTIES
                                                                OF
                                                                MERCHANTABILITY,
                                                                NON-INFRINGEMENT,
                                                                AND FITNESS FOR
                                                                PARTICULAR
                                                                PURPOSE.
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                lineHeight:
                                                                    '150%',
                                                                fontSize:
                                                                    '15px',
                                                                fontFamily:
                                                                    '"border:none',
                                                                padding: 0,
                                                            }}>
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '150%',
                                                                    fontFamily:
                                                                        'Roboto',
                                                                }}>
                                                                THE FOREGOING
                                                                DOES NOT AFFECT
                                                                ANY WARRANTIES
                                                                THAT CANNOT BE
                                                                EXCLUDED OR
                                                                LIMITED UNDER
                                                                APPLICABLE LAW.
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={props.handleClose}
                                        ref={cancelButtonRef}>
                                        OK
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default DisclaimerModal;
