import React, { Component } from 'react';
import GnogenLoader from '../GnogenLoader';

class GnogenImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            src: props.src,
            errored: false,
            loaded: false,
            loadFaded: false,
        };
    }

    componentWillMount() {}

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.src !== newProps.src) {
            this.setState({
                src: newProps.src,
                errored: false,
                loaded: false,
                loadFaded: false,
            });
        }
    }

    onLoad = () => {
        let thisObj = this;
        if (!this.state.loaded) {
            this.setState(
                {
                    //loaded: true,
                },
                () => {
                    setTimeout(() => {
                        thisObj.setState({ loadFaded: true });
                    }, 150);
                }
            );
        }
    };

    onError = (err) => {
        if (
            !this.state.errored &&
            this.props.fallbackSrc !== null &&
            this.props.fallbackSrc !== undefined
        ) {
            this.setState({
                src: this.props.fallbackSrc,
                errored: true,
            });
        } else {
            if (this.props.retry) {
                let thisObj = this;
                let previousSrc = this.state.src;
                setTimeout(() => {
                    thisObj.setState({ src: null }, () => {
                        thisObj.setState({ src: previousSrc });
                    });
                }, 3000);
            } else {
                if (this.props.onError) {
                    this.props.onError(err);
                }
            }
        }
    };

    renderLoading() {
        return null;
        if (!this.state.loadFaded || this.props.forceLoader) {
            let loadingClassname =
                'absolute w-1/2 h-1/2 animate-pulse top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            if (this.state.loaded) {
                loadingClassname = loadingClassname + ' transparent';
            }
            return <GnogenLoader className={loadingClassname} />;
        } else {
            // Loading animation faded out.
            return null;
        }
    }

    render() {
        const { src } = this.state;
        let { src: _1, fallbackSrc: _2, className, ...props } = this.props;
        className = className + ' eased';
        if (!this.state.loaded) {
            className = className + ' transparent';
        }

        let onError = this.onError;
        props = { dispatch: 0, className: className, onError: onError }; // Remove dispath prop

        return (
            <div className="w-full h-full relative">
                <img
                    src={src}
                    onLoad={this.onLoad}
                    onError={this.onError}
                    {...props} // Remove dispath prop
                />
                {this.renderLoading()}
            </div>
        );
    }
}

export default GnogenImage;
