import * as React from 'react';
import Localization from '../../utils/localization';
import TermsModal from '../TermsModal';
import DisclaimerModal from '../DisclaimerModal';
import PrivacyModal from '../PrivacyModal';
import { pageLayouts } from '../../utils/config';
import { useBasePath } from '../../utils/utils';

const Footer = (props) => {
    const basePath = useBasePath();
    const [showTerms, setShowTerms] = React.useState(false);
    const [showPrivacy, setShowPrivacy] = React.useState(false);
    const [showDisclaimer, setShowDisclaimer] = React.useState(false);

    const links = [
        {
            title: Localization.footer.terms,
            clickHandler: () => {
                setShowTerms(true);
            },
        },
        {
            title: Localization.footer.privacy,
            clickHandler: () => {
                setShowPrivacy(true);
            },
        },
        {
            title: Localization.footer.disclaimer,
            clickHandler: () => {
                setShowDisclaimer(true);
            },
        },
    ];

    return (
        <div
            className={`flex w-full items-center mt-auto justify-center text-sm ${
                props.dark ? 'text-white' : 'text-[rgb(0,0,0,0.8)]'
            } 
            ${pageLayouts[basePath]?.footerColor === 'black' ? 'bg-black' : ''}
            text-shadow-light font-roboto font-bold py-4`}>
            <TermsModal
                open={showTerms}
                handleClose={() => {
                    setShowTerms(false);
                }}
            />
            <DisclaimerModal
                open={showDisclaimer}
                handleClose={() => {
                    setShowDisclaimer(false);
                }}
            />
            <PrivacyModal
                open={showPrivacy}
                handleClose={() => {
                    setShowPrivacy(false);
                }}
            />
            <div
                className={`flex w-full items-center justify-between ${
                    pageLayouts[basePath]?.footerSize
                        ? pageLayouts[basePath]?.footerSize
                        : 'max-w-10xl'
                }`}>
                <span className="h-fit flex flex-col sm:flex-row px-4">
                    <span>&copy;{Localization.footer.gnogen}&nbsp;</span>
                    <span>{Localization.footer.allrights}</span>
                </span>
                <div className="flex flex-col sm:flex-row h-fit justify-center items-center">
                    {links.map((link) => {
                        return (
                            <span
                                className="px-4 py-2 cursor-pointer"
                                key={link.title}
                                onClick={link.clickHandler}>
                                {link.title}
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Footer;
