import './App.scss';
import Router from './routers/CoreRouter';
import { inject } from '@vercel/analytics';

inject();

function App() {
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
