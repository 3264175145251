import React, { Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

const defaultNftValues = {
    url: '',
    name: '',
    attributes: {},
};

function NFTPreview({ open, handleClose, nft }) {
    const [nftValues, setNftValues] = React.useState(defaultNftValues);
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        if (!nft) {
            setNftValues(defaultNftValues);
        } else {
            setNftValues(nft);
        }
    }, [nft, setNftValues]);

    const renderAttribute = (key, placeholder, classnames) => {
        let attribute = nftValues?.attributes[key];
        if (attribute) {
            attribute = attribute.toString().toUpperCase();
        }
        return (
            <div className={`flex flex-col ${classnames}`}>
                <span className="">{placeholder}</span>
                <span className="font-bold">{attribute}</span>
            </div>
        );
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-center sm:items-center justify-center min-h-full h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="max-h-[95%] w-[28rem] sm:max-h-auto relative bg-black rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 z-50">
                                <div className="bg-black pb-4 sm:pb-4 text-white">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center text-sm sm:text-left font-roboto relative">
                                            <div
                                                className="flex items-center justify-center h-10 w-10 absolute top-0 right-0 cursor-pointer"
                                                onClick={handleClose}>
                                                <XIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="p-8">
                                                    <img
                                                        src={nftValues.url}
                                                        className="w-full object-cover rounded-lg"
                                                    />
                                                </div>
                                                <div className="flex flex-col px-4 sm:px-8 w-full">
                                                    <span className="pb-3 text-center text-lg">
                                                        {nftValues.name}
                                                    </span>
                                                    <div className="flex w-full justify-between py-3">
                                                        {renderAttribute(
                                                            'POWER',
                                                            'PWR',
                                                            'items-center'
                                                        )}
                                                        {renderAttribute(
                                                            'ATTACK',
                                                            'ATK',
                                                            'items-center'
                                                        )}
                                                        {renderAttribute(
                                                            'DEFENSE',
                                                            'DEF',
                                                            'items-center'
                                                        )}
                                                        {renderAttribute(
                                                            'SPEED',
                                                            'SPD',
                                                            'items-center'
                                                        )}
                                                        {renderAttribute(
                                                            'INTELLIGENCE',
                                                            'IQ',
                                                            'items-center'
                                                        )}
                                                        {renderAttribute(
                                                            'HEART',
                                                            'HEART',
                                                            'items-center'
                                                        )}
                                                    </div>
                                                    <div className="flex w-full py-3">
                                                        {renderAttribute(
                                                            'BACKGROUND',
                                                            'BACKGROUND',
                                                            'items-center flex-1'
                                                        )}
                                                        {renderAttribute(
                                                            'SCALES',
                                                            'SCALES',
                                                            'items-center flex-1'
                                                        )}
                                                    </div>
                                                    <div className="flex w-full py-3">
                                                        {renderAttribute(
                                                            'CIRCLE',
                                                            'CIRCLE',
                                                            'items-center flex-1'
                                                        )}
                                                        {renderAttribute(
                                                            'CHEST',
                                                            'CHEST',
                                                            'items-center flex-1'
                                                        )}
                                                    </div>
                                                    <div className="flex w-full py-3">
                                                        {renderAttribute(
                                                            'ELEMENTAL',
                                                            'ELEMENTAL',
                                                            'items-center flex-1'
                                                        )}
                                                        {renderAttribute(
                                                            'SPIKES',
                                                            'SPIKES',
                                                            'items-center flex-1'
                                                        )}
                                                    </div>
                                                    <div className="flex w-full py-3">
                                                        {renderAttribute(
                                                            'COLOR',
                                                            'COLOR',
                                                            'items-center flex-1'
                                                        )}
                                                        {renderAttribute(
                                                            'ARMOUR',
                                                            'ARMOUR',
                                                            'items-center flex-1'
                                                        )}
                                                    </div>
                                                    <div className="flex w-full py-3">
                                                        {renderAttribute(
                                                            'EYES',
                                                            'EYES',
                                                            'items-center flex-1'
                                                        )}
                                                        {renderAttribute(
                                                            'HORN',
                                                            'HORN',
                                                            'items-center flex-1'
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default NFTPreview;
