import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ExternalLinkIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import Tooltip from '@mui/material/Tooltip';
import Localization from '../../utils/localization';
import { ReactComponent as GnogenLogo } from '../../assets/images/gnogen-logo.svg';
import SelectableTitle from '../SelectableTitle';
import Slash from '../StylizedSlash';
import { pageLayouts } from '../../utils/config';
import { useBasePath } from '../../utils/utils';

const Header = (props) => {
    const basePath = useBasePath();
    const navigate = useNavigate();
    const location = useLocation();
    const [hoveredLink, setHoveredLink] = React.useState(null);
    const [blurryBg, setBlurryBg] = React.useState(false);

    const handleScroll = () => {
        if (pageLayouts[basePath]?.headerBlurry === false) {
            // Do not handle header blur
        } else {
            if (
                document.documentElement.scrollTop ||
                document.body.scrollTop > 0
            ) {
                setBlurryBg(true);
            } else {
                setBlurryBg(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const links = [
        { title: Localization.header.collections, url: '/collections' },
        { title: Localization.header.elrond, url: '/elrond' },
        { title: Localization.header.greatWar, link: 'https://gng.gnogen.io' },
        {
            title: Localization.header.gnogonsRevamped,
            link: 'https://gnogons.gnogen.io',
        },
        { title: Localization.header.shop, link: 'https://shop.gnogen.io/' },
        {
            title: Localization.header.analytics,
            link: 'https://omniscient.tools/partners/GNOGEN-8156fb',
        },
        {
            title: Localization.header.about,
            link: 'https://gnogen.gitbook.io/gnogen/',
        },
    ];

    const renderNavMenuItem = (link) => {
        if (link.url || link.link) {
            let exactCurrent = location.pathname === link.url;
            let current = location.pathname.includes(link.url);
            let hovered = hoveredLink === link.title;
            return (
                <div
                    key={link.title}
                    className={
                        'flex justify-center items-center font-gnogen group px-1 cursor-pointer'
                    }
                    onClick={() => {
                        if (link.url) {
                            if (!exactCurrent) navigate(link.url);
                        } else if (link.link) {
                            window.open(link.link, '_blank');
                        }
                    }}
                    onMouseEnter={() => {
                        setHoveredLink(link.title);
                    }}
                    onMouseLeave={() => {
                        setHoveredLink(null);
                    }}>
                    <SelectableTitle
                        text={link.title}
                        textClass={`font-roboto font-bold animated-text px-1 text-sm text-shadow-light whitespace-nowrap ${
                            props.linkClass
                        } ${
                            props.dark
                                ? 'text-white group-hover:glow'
                                : 'text-black'
                        }`}
                        containerClass="px-2"
                        width={0.4}
                        height={1.1}
                        disabled
                        selected={current || hovered}
                        dashColor={props.dashColor}
                    />
                </div>
            );
        } else {
            return (
                <Tooltip
                    key={link.title}
                    title={<span className="subtitle">Coming Soon</span>}
                    placement="top"
                    leaveDelay={200}>
                    <div className="flex justify-center items-center font-gnogen group px-1">
                        <SelectableTitle
                            text={link.title}
                            textClass={`font-roboto font-bold animated-text text-shadow-light text-sm whitespace-nowrap ${
                                props.dark
                                    ? 'text-white hover-glow'
                                    : 'text-black'
                            }`}
                            containerClass="px-2"
                            width={0.75}
                            height={1.1}
                            disabled
                            dashColor={props.dashColor}
                        />
                    </div>
                </Tooltip>
            );
        }
    };
    const renderDrawerMenuItem = (link) => {
        let exactCurrent = location.pathname === link.url;
        return (
            <div
                className={`w-fit text-xl py-4 cursor-pointer`}
                onClick={() => {
                    if (link.url) {
                        if (!exactCurrent) navigate(link.url);
                    } else if (link.link) {
                        window.open(link.link, '_self');
                    }
                }}>
                {/*<SelectableTitle
                    text={link.title}
                    textClass={`px-1 text-shadow-light ${props.linkClass} ${
                        props.dark ? 'text-white' : 'text-black'
                    }`}
                    containerClass="px-2 "
                    width={0.4}
                    height={1.5}
                    disabled
                    selected={current}
                    dashColor={props.dashColor}
                />*/}
                <span className="px-1 text-shadow-light text-black">
                    {link.title}
                </span>
            </div>
        );
    };

    let logoHovered = hoveredLink === '/';
    let homeCurrent = location.pathname === '/';
    return (
        <Disclosure
            as="nav"
            className={`fixed inset-0 flex w-full h-navbarheight z-40 animated-background animated-backdrop backdrop-blur-sm ${
                blurryBg
                    ? ' backdrop-opacity-100 bg-white/30'
                    : 'backdrop-opacity-0 '
            } from-white to-transparent`}>
            {({ open }) => (
                <>
                    <div
                        className={
                            'hidden xl:flex ml-auto mr-auto w-full px-12 justify-between'
                        }>
                        <div
                            className="relative z-50 cursor-pointer flex justify-center items-center flex-[2]"
                            onClick={() => {
                                navigate('/');
                            }}
                            onMouseEnter={() => {
                                setHoveredLink('/');
                            }}
                            onMouseLeave={() => {
                                setHoveredLink(null);
                            }}>
                            <div className="flex relative items-center">
                                <Slash
                                    {...props}
                                    className={'absolute left-0 pt-1'}
                                    hidden={!logoHovered && !homeCurrent}
                                    width={0.75}
                                    height={3.25}
                                    color={props.dashColor}
                                />
                                <GnogenLogo
                                    className={`${
                                        props.dark ? 'fill-white' : 'fill-black'
                                    } w-36 lg:w-56 ml-4 mr-6`}
                                />
                                <Slash
                                    {...props}
                                    className={'absolute right-0 pt-1'}
                                    hidden={!logoHovered && !homeCurrent}
                                    width={0.75}
                                    height={3.25}
                                    color={props.dashColor}
                                />
                            </div>
                        </div>
                        <div className="flex ml-auto flex-[8] gap-4">
                            {links.map((link) => {
                                return renderNavMenuItem(link);
                            })}
                        </div>
                    </div>
                    <div
                        className={
                            'flex xl:hidden ml-auto mr-auto w-full justify-between'
                        }>
                        <div
                            className="relative z-50 cursor-pointer w-40 lg:w-48 flex items-center"
                            onClick={() => {
                                navigate('/');
                            }}>
                            <GnogenLogo
                                className={`${
                                    props.dark && !open
                                        ? 'fill-white'
                                        : 'fill-black'
                                } w-full animated-fill`}
                            />
                        </div>
                        <Disclosure.Button className="inline-flex items-center z-50 justify-center p-2 rounded-md text-gray-400 hover:bg-transparent focus:bg-transparent focus:outline-none">
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XIcon
                                    className={`text-black block h-6 w-6`}
                                    aria-hidden="true"
                                />
                            ) : (
                                <MenuIcon
                                    className={`${
                                        props.dark ? 'text-white' : 'text-black'
                                    }block h-6 w-6`}
                                    aria-hidden="true"
                                />
                            )}
                        </Disclosure.Button>
                    </div>
                    {/*<Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0">
                        {/*
              Don't forget to add `static` to your Disclosure.Panel!

                        <Disclosure.Panel static></Disclosure.Panel>
                    </Transition>*/}
                    <Disclosure.Panel className="xl:hidden absolute w-screen h-screen bg-white bg-opacity-70 backdrop-blur-lg pt-navbarheight">
                        <div className="px-2 pt-6 pb-3 space-y-1">
                            {links.map((item) => {
                                return (
                                    <div key={'drawer-' + item.title}>
                                        <Disclosure.Button
                                            as="a"
                                            href={item.url}
                                            className=""
                                            aria-current={
                                                item.current
                                                    ? 'page'
                                                    : undefined
                                            }>
                                            {renderDrawerMenuItem(item)}
                                        </Disclosure.Button>
                                    </div>
                                );
                            })}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Header;
