import Localization from './localization';

import emidasEmblem from '../assets/images/emidas-grayscale.png';
import gnogonsEmblem from '../assets/images/gnogon-logo-96.png';
import aermesEmblem from '../assets/images/aermes-inklogo.png';
import validatorsEmblem from '../assets/images/validators-inklogo.png';
import dogaEmblem from '../assets/images/doga-inklogo.png';
import emidasAct1Banner from '../assets/images/collections/emidas-act1-banner.jpg';
import emidasAct1Video from '../assets/videos/emidas-act-1.mp4';
import emidasAct2Banner from '../assets/images/collections/emidas-act2-banner.jpg';
import emidasAct2Video from '../assets/videos/emidas-act-2.mp4';
import emidasAct3Banner from '../assets/images/collections/emidas-act3-banner.jpg';
import emidasAct3Video from '../assets/videos/emidas-act-3.mp4';
import emidasAct4Banner from '../assets/images/collections/emidas-act4-banner.jpg';
import emidasAct4Video from '../assets/videos/emidas-act-4.mp4';
import emidasAct5Banner from '../assets/images/collections/emidas-act5-banner.jpg';
import emidasAct5Video from '../assets/videos/emidas-act-5.mp4';
import emidasAct6Banner from '../assets/images/collections/emidas-act6-banner.jpg';
import emidasAct6Video from '../assets/videos/emidas-act-6.mp4';
import emidasAct7Banner from '../assets/images/collections/emidas-act7-banner.jpg';
import emidasAct7Video from '../assets/videos/emidas-act-7.mp4';
import aermesAct1Banner from '../assets/images/collections/emidas-act1-banner.jpg';
import aermesAct1Video from '../assets/videos/aermes-act-1.mp4';
import aermesAct2Banner from '../assets/images/collections/emidas-act2-banner.jpg';
import aermesAct2Video from '../assets/videos/aermes-act-2.mp4';
import aermesAct3Banner from '../assets/images/collections/emidas-act3-banner.jpg';
import aermesAct3Video from '../assets/videos/aermes-act-3.mp4';
import aermesAct4Banner from '../assets/images/collections/emidas-act4-banner.jpg';
import aermesAct4Video from '../assets/videos/aermes-act-4.mp4';
import aermesAct5Banner from '../assets/images/collections/emidas-act5-banner.jpg';
import aermesAct5Video from '../assets/videos/aermes-act-5.mp4';
import aermesAct6Banner from '../assets/images/collections/emidas-act6-banner.jpg';
import aermesAct6Video from '../assets/videos/aermes-act-6.mp4';
import aermesAct7Banner from '../assets/images/collections/emidas-act7-banner.jpg';
import aermesAct7Video from '../assets/videos/aermes-act-7.mp4';
import dogaAct1Banner from '../assets/images/collections/emidas-act1-banner.jpg';
import dogaAct1Video from '../assets/videos/doga-act-1.mp4';
import { ReactComponent as filterSale } from '../assets/icons/nftFilters/filter-sale.svg';
import { ReactComponent as filterArmour } from '../assets/icons/nftFilters/filter-armour.svg';
import { ReactComponent as filterAttack } from '../assets/icons/nftFilters/filter-attack.svg';
import { ReactComponent as filterBackground } from '../assets/icons/nftFilters/filter-background.svg';
import { ReactComponent as filterChest } from '../assets/icons/nftFilters/filter-chest.svg';
import { ReactComponent as filterColor } from '../assets/icons/nftFilters/filter-color.svg';
import { ReactComponent as filterDefense } from '../assets/icons/nftFilters/filter-defense.svg';
import { ReactComponent as filterCircle } from '../assets/icons/nftFilters/filter-elemental.svg';
import { ReactComponent as filterElemental } from '../assets/icons/nftFilters/filter-elemental2.svg';
import { ReactComponent as filterEyes } from '../assets/icons/nftFilters/filter-eyes.svg';
import { ReactComponent as filterHeart } from '../assets/icons/nftFilters/filter-heart.svg';
import { ReactComponent as filterHorns } from '../assets/icons/nftFilters/filter-horns.svg';
import { ReactComponent as filterIntelligence } from '../assets/icons/nftFilters/filter-intelligence.svg';
import { ReactComponent as filterPower } from '../assets/icons/nftFilters/filter-power.svg';
import { ReactComponent as filterScales } from '../assets/icons/nftFilters/filter-scales.svg';
import { ReactComponent as filterSpeed } from '../assets/icons/nftFilters/filter-speed.svg';
import { ReactComponent as filterSpikes } from '../assets/icons/nftFilters/filter-spikes.svg';
import gnogonsLogo from '../assets/images/collections/gnogons-logo.jpg';
import gnogonsSeal from '../assets/images/gnogen-official-icon.svg';
import gnogonsData from '../assets/data/gnogonsNfts.json';
import gnogonsFilters from '../assets/data/gnogonsFilters.json';
import validatorsLogo from '../assets/images/collections/gnogons-logo.jpg';
import validatorsSeal from '../assets/images/gnogen-official-icon.svg';
import validatorsData from '../assets/data/validatorsNfts.json';
import validatorsFilters from '../assets/data/validatorsFilters.json';

const PROD = false;

export const appConstants = {
    BACKENDADDRESS: PROD ? 'prod backend' : 'dev backend',
    ADMINROUTE: 'admin/',
    USERSROUTE: 'users/',
    ENTRIESROUTE: 'entries/',
    LOGINMETHOD: 'login/',

    APPLICATION_URL: PROD ? 'fanstories.com' : 'dev.fanstories.tv',
};

export const pageLayouts = {
    '/': { headerColor: 'white', footerColor: 'black' },
    '/elrond': { footerSize: 'max-w-8xl' },
    '/collections/comics': { headerColor: 'white' },
    '/sandbox': { headerBlurry: false },
};

export const gnogonFilterIcons = {
    'ON SALE': filterSale,
    'BACKGROUND': filterBackground,
    'CIRCLE': filterCircle,
    'ELEMENTAL': filterElemental,
    'COLOR': filterColor,
    'EYES': filterEyes,
    'SCALES': filterScales,
    'CHEST': filterChest,
    'SPIKES': filterSpikes,
    'ARMOUR': filterArmour,
    'HORN': filterHorns,
};

export const collections = [
    {
        id: 'emidas',
        type: 'comics',
        title: 'Emidas',
        subtitle: '7 ' + Localization.collections.acts,
        state: 'SOLD OUT',
        emblem: emidasEmblem,
        collection: 'GENESIS',
        mintage: 200,
        year: 2021,
        comics: [
            {
                title: 'Act I',
                subtitle: 'ZERO TO ONE',
                banner: emidasAct1Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-1-zero-to-one',
                video: emidasAct1Video,
                texts: Localization.collections.comics.emidas[0].texts,
            },
            {
                title: 'Act II',
                subtitle: 'FEAR, UNCERTAINTY, DOUBT',
                banner: emidasAct2Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-2-fear.-uncertainty.-doubt.',
                video: emidasAct2Video,
                texts: Localization.collections.comics.emidas[1].texts,
            },
            {
                title: 'Act III',
                subtitle: 'DEFI THE ENEMY, ENTER THE FLYWHEEL',
                banner: emidasAct3Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-3-defi-the-enemy.-enter-the-flywheel.',
                video: emidasAct3Video,
                texts: Localization.collections.comics.emidas[2].texts,
            },
            {
                title: 'Act IV',
                subtitle: 'THE BLOCKCHAIN TRILEMMA',
                banner: emidasAct4Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-4-the-blockchain-trilemma',
                video: emidasAct4Video,
                texts: Localization.collections.comics.emidas[3].texts,
            },
            {
                title: 'Act V',
                subtitle: 'POWER OVERWHELMING',
                banner: emidasAct5Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-5-power-overwhelming',
                video: emidasAct5Video,
                texts: Localization.collections.comics.emidas[4].texts,
            },
            {
                title: 'Act VI',
                subtitle: 'THE WEIGHT OF THE WORLD',
                banner: emidasAct6Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-6-the-weight-of-the-world',
                video: emidasAct6Video,
                texts: Localization.collections.comics.emidas[5].texts,
            },
            {
                title: 'Act VII',
                subtitle: 'ALL ROADS LEAD TO ELROND',
                banner: emidasAct7Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/emidas/emidas-act-7-all-roads-lead-to-elrond',
                video: emidasAct7Video,
                texts: Localization.collections.comics.emidas[6].texts,
            },
        ],
    },
    {
        id: 'gnogons',
        type: 'nfts',
        title: 'Gnogons',
        subtitle: '10,000 PFP',
        state: 'SOLD OUT',
        emblem: gnogonsEmblem,
        collection: 'GENESIS',
        mintage: '10,000',
        year: 2021,
        filterIcons: gnogonFilterIcons,
        filters: gnogonsFilters,
        data: gnogonsData,
        collectionId: 'GNOGONS-73222b',
        logo: gnogonsLogo,
        seal: gnogonsSeal,
    },
    {
        id: 'aermes',
        type: 'comics',
        title: 'AERMES',
        subtitle: '7 ' + Localization.collections.acts,
        state: 'AEROVEK STAKING REWARD',
        emblem: aermesEmblem,
        collection: 'GENESIS',
        mintage: '350',
        comingSoon: true,
        year: 2022,
        comics: [
            {
                title: 'Act I',
                subtitle: 'LIFT-OFF THE CHARTS',
                banner: emidasAct1Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/aermes-act-1-lift-off-the-charts',
                video: aermesAct1Video,
                texts: Localization.collections.comics.aermes[0].texts,
            },
            {
                title: 'Act II',
                subtitle: 'FLIGHT OR FIGHT',
                banner: emidasAct2Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/aermes-act-2-flight-or-fight',
                video: aermesAct2Video,
                texts: Localization.collections.comics.aermes[1].texts,
            },
            {
                title: 'Act III',
                subtitle: 'FLYING CLOSER TO THE SUN',
                banner: emidasAct3Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/aermes-act-3-flying-closer-to-the-sun',
                video: aermesAct3Video,
                texts: Localization.collections.comics.aermes[2].texts,
            },
            {
                title: 'Act IV',
                subtitle: 'WHEN THE STUDENT IS READY',
                banner: emidasAct4Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/aermes-act-4-when-the-student-is-ready',
                video: aermesAct4Video,
                texts: Localization.collections.comics.aermes[3].texts,
            },
            {
                title: 'Act V',
                subtitle: 'THE TEACHER APPEARS',
                banner: emidasAct5Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/aermes-act-5-the-teacher-appears',
                video: aermesAct5Video,
                texts: Localization.collections.comics.aermes[4].texts,
            },
            {
                title: 'Act VI',
                subtitle: 'REBORN',
                banner: emidasAct6Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/act-6-reborn',
                video: aermesAct6Video,
                texts: Localization.collections.comics.aermes[5].texts,
            },
            {
                title: 'Act VII',
                subtitle: 'A HERO IS BORN',
                banner: emidasAct7Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/act-7-a-hero-is-born',
                video: aermesAct7Video,
                texts: Localization.collections.comics.aermes[6].texts,
            },
            {
                title: 'Act VIII',
                subtitle: 'GO AND FIND THEM',
                banner: emidasAct7Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/aermes/act-8-go-and-find-them',
                video: aermesAct7Video,
                texts: Localization.collections.comics.aermes[7].texts,
            },
        ],
    },
    {
        id: 'validators',
        type: 'nfts',
        title: 'VALIDATORS',
        subtitle: '10,000 PFP',
        state: 'NOW MINTING',
        emblem: validatorsEmblem,
        collection: 'V2',
        mintage: '10,000',
        comingSoon: false,
        year: 2022,
        filterIcons: gnogonFilterIcons,
        filters: validatorsFilters,
        data: validatorsData,
        collectionId: 'VTWO-8d2640',
    },
    {
        id: 'doga',
        type: 'comics',
        title: 'DOGA',
        subtitle: '7 ' + Localization.collections.acts,
        state: 'JULY - AUGUST',
        emblem: dogaEmblem,
        collection: 'GENESIS',
        mintage: '777',
        comingSoon: true,
        year: 2022,
        comics: [
            {
                title: 'Act I',
                subtitle: 'Do Only Good Always',
                banner: emidasAct1Banner,
                url: 'https://gnogen.gitbook.io/gnogen/projects/gnogen-comics/doga/doga-act-1-do-only-good-always',
                video: dogaAct1Video,
                texts: Localization.collections.comics.doga[0].texts,
            },
        ],
    },
];
