import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CollectionComic from '../CollectionComic/';
import ComicRead from '../ComicRead/';
import CollectionNft from '../CollectionNft/';
import { collections } from '../../utils/config';

const CollectionRoute = () => {
    let { id, act } = useParams(); // Unpacking and retrieve id
    const [collection, setCollection] = React.useState(null);

    useEffect(() => {
        // Parse through the collection items and retrieve all unique selectors
        collections.forEach((col, index) => {
            if (col.id === id) {
                if (act !== null && act !== undefined) {
                    try {
                        let actIndex = parseInt(act) - 1;
                        if (
                            col.type === 'comics' &&
                            col.comics.length > actIndex
                        ) {
                        }
                    } catch (e) {}
                }
                setCollection(col);
                return;
            }
        });
    }, [setCollection, id, act]);

    if (collection?.type === 'comics') {
        if (act !== null && act !== undefined) {
            return <ComicRead collection={collection} />;
        } else {
            return <CollectionComic collection={collection} />;
        }
    } else if (collection?.type === 'nfts') {
        return <CollectionNft collection={collection} />;
    } else {
        return null;
    }
};

export default CollectionRoute;
