import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
} from '@heroicons/react/outline';

//https://github.com/riancarlosdev/carousel-react-rcdev/blob/master/src/index.tsx

function Carrousel(props) {
    const [stateCarosel, setStateCarousel] = useState();
    const [leftScrollable, setLeftScrollable] = useState(false);
    const [rightScrollable, setRightScrollable] = useState(true);
    const Carousel = useRef();

    const handleCarousel = useCallback(() => {
        if (Carousel.current) {
            const carousel = Carousel.current;
            setStateCarousel({
                ...stateCarosel,
                width_carosel: carousel.clientWidth,
                qnt_childrens: carousel.children.length,
                width_childrens: props.scrollWidth
                    ? props.scrollWidth
                    : carousel.children.item(1)?.clientWidth,
                max_width_carousel:
                    (carousel.children.length - 1) *
                    carousel.children.item(1)?.clientWidth,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Carousel.current]);

    const handleCarouselAction = (e) => {
        e.preventDefault();
        if (!Carousel.current || !stateCarosel) {
            return;
        }

        switch (e.currentTarget.id) {
            case 'next':
                setLeftScrollable(
                    Carousel.current.scrollLeft + stateCarosel?.width_childrens >
                        0
                );
                setRightScrollable(
                    stateCarosel.width_carosel +
                        Carousel.current.scrollLeft +
                        stateCarosel.width_childrens <
                        stateCarosel.max_width_carousel
                );
                Carousel.current.scrollLeft += stateCarosel.width_childrens;
                return;

            case 'prev':
                setLeftScrollable(
                    Carousel.current.scrollLeft - stateCarosel.width_childrens >
                        0
                );
                setRightScrollable(
                    stateCarosel.width_carosel +
                        Carousel.current.scrollLeft -
                        stateCarosel.width_childrens <
                        stateCarosel.max_width_carousel
                );
                Carousel.current.scrollLeft -= stateCarosel.width_childrens;
                return;

            default:
                return null;
        }
    };

    useEffect(() => handleCarousel(), [handleCarousel]);

    return (
        <div className={`relative w-full ${props.className}`}>
            <div
                className={`max-w-full w-full overflow-x-scroll flex scroll-smooth scrollbar-hidden  ${props.containerClassName}`}
                ref={Carousel}>
                <div className="absolute flex justify-between max-w-full w-full h-full">
                    <button
                        onClick={handleCarouselAction}
                        id="prev"
                        className={`h-full bg-gnogen-transparent-gray animated-opacity z-20 ${
                            props.buttonsClassName
                        } ${leftScrollable ? 'opacity-100' : 'opacity-0'}`}>
                        <ArrowCircleLeftIcon className="h-12 w-12 text-gnogen-white" />
                    </button>
                    <button
                        onClick={handleCarouselAction}
                        id="next"
                        className={`h-full bg-gnogen-transparent-gray animated-opacity z-20 ${
                            props.buttonsClassName
                        } ${rightScrollable ? 'opacity-100' : 'opacity-0'}`}>
                        <ArrowCircleRightIcon className="h-12 w-12 text-gnogen-white" />
                    </button>
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default Carrousel;
