import React from 'react';

const Divider = ({ className, dividerClassName }) => {
    return (
        <div className={`w-full max-w-7xl ${className}`}>
            <div
                className={`border border-gnogen-white ${dividerClassName}`}></div>
        </div>
    );
};

export default Divider;
