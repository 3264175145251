import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetListings = (collectionId) => {
    const params = {
        collection: collectionId,
        filters: {
            onSale: true,
            attributes: [],
            auctionTypes: ['Nft', 'SftOnePerPayment'],
            range: {
                min: 0,
                max: 9007199254740991,
                type: 'saleInfoNft/min_bid_short',
            },
        },
        name: '',
        orderBy: ['saleInfoNft/min_bid_short asc'],
        select: ['saleInfoNft', 'identifier'],
        skip: 0,
        top: 100000,
    };
    return useQuery(['getListings', collectionId], async () => {
        if (collectionId === null) {
            return null;
        }
        const result = await axios.post(
            'https://xoxnoapi.azureedge.net/nftsFromCollection',
            params
        );
        const listings = {};
        result.data.results.forEach((listing, index) => {
            listings[listing.identifier] = listing;
        });
        //console.log('Query result: ' + JSON.stringify(listings));
        return listings;
    });
};

export default useGetListings;
