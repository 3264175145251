import React from 'react';
import { Outlet } from 'react-router-dom';
import { pageLayouts } from '../utils/config';
import { useBasePath } from '../utils/utils';

import Header from '../components/Header';
import Footer from '../components/Footer';

const LightLayout = () => {
    const basePath = useBasePath();
    return (
        <div className="h-full bg-gnogen-white text-black relative flex flex-col items-center min-h-screen bg-gnogen-background">
            <Header dark={pageLayouts[basePath]?.headerColor === 'white'} />
            <div className="w-full flex justify-center">
                <Outlet />
            </div>
            <Footer dark={pageLayouts[basePath]?.footerColor === 'black'} />
        </div>
    );
};
export default LightLayout;
